import {Component, Input, Output, Inject, ViewChild, OnInit, ElementRef} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../_services/api.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {EventEmitter} from '@angular/core';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {DisplaynametonamepipePipe} from '../_pipes/displaynametonamepipe.pipe';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-roles-edit-button',
  templateUrl: './roles-edit-button.component.html'
})
export class RolesEditButtonComponent {
  dialogRefDataMode;

  @Input() roletobeedited = null;
  @Output() refreshrequest = new EventEmitter<string>();
  @Output() showloader = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public permissionchecker: PermissioncheckerService,
    private apiService: ApiService,
  ) {
  }

  openDialog(mode: string = 'create') {
    switch (mode) {
      case 'create':
        this.dialogRefDataMode = 'create';
        break;
      case 'edit': {
        this.dialogRefDataMode = 'edit';
        break;
      }
    }

    const dialogRef = this.dialog.open(RolesEditComponent, {
      width: '1200px',
      data: {
        role: this.roletobeedited,
        mode: this.dialogRefDataMode,
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed === true) {
        this.snackBar.open('Regelset erfolgreich gespeichert!', 'OK', {
          duration: 4000,
        });
        this.showLoaderOnParent();
        this.requestRefresh();
      } else {
        this.snackBar.open('Ihre Änderungen wurden nicht gespeichert', 'OK', {
          duration: 4000,
        });
      }
    });
  }

  deleteRole(roleid) {
    this.showLoaderOnParent();
    this.apiService.deleteRole(roleid).then(
      data => {
        this.requestRefresh();
      },
    );
  }

  showLoaderOnParent() {
    this.showloader.emit('Show-Loader');
  }

  requestRefresh() {
    this.refreshrequest.emit('Request-Refresh');
  }
}

@Component({
  selector: 'app-role-edit',
  templateUrl: './roles-edit.component.html'
})
export class RolesEditComponent implements OnInit {
  roleseditresponse;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  roleseditform: FormGroup;
  submitted = false;
  isSaving = false;
  loading = true;
  permissions: object;
  permissionsarray: FormArray;
  formconvertedname = '';
  // tslint:disable-next-line
  role_description = new FormControl((this.data.mode === 'edit' ? this.data.role.description : ''), Validators.required);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RolesEditButtonComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authentificationService: AuthenticationService,
  ) {
  }

  postRole(mode) {
    if (this.roleseditform.invalid) {
      return;
    } else {
      this.isSaving = true;
      this.roleseditform.value.role_name = this.formconvertedname;
    }

    if ( mode === 'create' ) {
      this.apiService.createRole(this.roleseditform.value).then(
        data => {
          this.roleseditresponse = data;
          this.submitted = true;
          this.isSaving = false;
          this.closeDialogSaving(true);
        },
      ).catch((error: any) => {
        this.submitted = false;
        this.isSaving = false;
        this.snackBar.open('Es ist ein Fehler aufgetreten. Der Role wurde nicht angelegt.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      });
    }

    if ( mode === 'edit' ) {
      const roleeditdata = this.roleseditform.value;
      roleeditdata.role_id = this.data.role.id;

      this.apiService.updateRole(roleeditdata).then(
        data => {
          this.roleseditresponse = data;
          this.submitted = true;
          this.isSaving = false;
          this.closeDialogSaving(true);
        },
      ).catch((error: any) => {
        this.submitted = false;
        this.isSaving = false;
        this.snackBar.open('Es ist ein Fehler aufgetreten. Ihre Änderungen wurden nicht gespeichert.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      });
    }
  }

  closeDialogSaving(success: true | false) {
    this.dialogRef.close(success);
  }

  createPermissionGroup(): FormGroup {
    return this.formBuilder.group({
      permission_name: [''],
      permission_display_name: [''],
      permission_is_active: [false],
    });
  }

  addPermissionGroup(): void {
    this.permissionsarray = this.roleseditform.get('permissions') as FormArray;
    this.permissionsarray.push(this.createPermissionGroup());
  }

  updatePermissionGroups(permissions) {
    const permissionGroup = this.roleseditform.get('permissions') as FormArray;
    let permissionindex = 0;
    permissionGroup.clear();

    for (const permission of permissions) {
      this.addPermissionGroup();
      this.permissionsarray.at(permissionindex).patchValue({
        permission_is_active: (this.data.mode === 'edit' ? this.data.role.permissions.filter(p => p.name === permission.name).length > 0 : permission.name.substr(0, 4).toLowerCase() === 'read'),
        permission_display_name: permission.display_name,
        permission_name: permission.name,
      });
      permissionindex = permissionindex + 1;
    }
  }

  get rolesEditFormData() {
    return this.roleseditform.get('permissions') as FormArray;
  }

  convertToDisplay(displayname: string): void {
    const valuetoinsert =  displayname.replace(/[^A-Z0-9]+/ig, '').toLowerCase();
    this.formconvertedname = valuetoinsert;
  }

  ngOnInit(): void {
    if (this.data.mode === 'edit') {
      this.convertToDisplay(this.data.role.name);
    }

    this.apiService.getPermissions().subscribe((permissions) => {
      this.permissions = permissions;
      this.updatePermissionGroups(permissions);
      this.loading = false;
    });

    this.roleseditform = this.formBuilder.group({
      role_name: [(this.data.mode === 'edit' ? this.data.role.name : '')],
      role_display_name: [(this.data.mode === 'edit' ? this.data.role.display_name : ''), Validators.required],
      role_description: this.role_description,
      permissions: this.formBuilder.array([this.createPermissionGroup()]),
    });
  }
}
