import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Production} from '../production';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from '../_services/api.service';

export class ProductionsDataSource implements DataSource<Production> {

  private productionsSubject = new BehaviorSubject<Production[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public productionsPayload = new BehaviorSubject<Production[]>([]);

  constructor(private apiService: ApiService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Production[] | ReadonlyArray<Production>> {
    return this.productionsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.productionsSubject.complete();
    this.loadingSubject.complete();
  }

  loadProductions(data) {
    this.loadingSubject.next(true);

    if (data.search.detailSearch) {
      this.apiService.detailSearchProductions(data).then(productions => {
        this.loadingSubject.next(false);
        this.productionsSubject.next(productions.data);
        this.productionsPayload.next(productions);
      });
    } else {
      this.apiService.searchProductions(data).then(productions => {
        this.loadingSubject.next(false);
        this.productionsSubject.next(productions.data);
        this.productionsPayload.next(productions);
      });
    }
  }
}
