import {Score} from './score';

// tslint:disable:variable-name
export class Production {
  data: ProductionPayload;
  from: number;
  to: number;
  last_page: number;
  total: number;
}

export class ProductionPayload {
  id: number;
  brid: string;
  created_at: string;
  updated_at: string;
  initial_gvr_creation;
  title: string;
  sub_title: string;
  genre: string;
  scores: Score[];
  created_by_user_id: number;
  updated_by_user_id: number;
  type: string;
  destination: string;
  broadcasts;
  year: number;
  ruleset_ids;
  leading_broadcaster_id;
  editorial_broadcaster_id;
}
