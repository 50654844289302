import {Component, OnInit} from '@angular/core';
import {ApiService} from '../_services/api.service';
import {Production} from '../production';
import {Ruleset} from '../ruleset';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html'
})
export class RulesComponent implements OnInit {
  loaded: boolean = false;
  rulesets: Ruleset[];

  constructor(
    private apiService: ApiService,
    public permissionchecker: PermissioncheckerService,
    private titleService: Title,
  ) {
  }

  ngOnInit() {

    this.titleService.setTitle("GVR-Tool | Regelwerk");

    this.apiService.getRulesets().subscribe((ruleset: Ruleset[]) => {
      this.rulesets = ruleset;
      this.loaded = true;
      for (let singleruleset of this.rulesets) {
        singleruleset.broadcasterBasicFactors.sort(function(a, b){
          if(a.broadcaster_name.toLowerCase( ) < b.broadcaster_name.toLowerCase( )) { return -1; }
          if(a.broadcaster_name.toLowerCase( ) > b.broadcaster_name.toLowerCase( )) { return 1; }
          return 0;
        });
      }
    });
  }

  refreshRules(): void {
    this.apiService.getRulesets().subscribe((ruleset: Ruleset[]) => {
      this.rulesets = ruleset;
      this.loaded = true;
    });
  }

  showLoader(): void {
    this.loaded = false;
  }
}
