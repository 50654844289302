<div class="search-field-wrapper">
  <form [formGroup]="publicationList.simpleSearchForm" (ngSubmit)="publicationList.searchPublications()" aria-label="Produktionssuche">
    <mat-form-field [class.blurredout]="publicationList.showexpertsearch === true" [attr.aria-hidden]="publicationList.showexpertsearch === true">
      <input matInput formControlName="simpleSearchInput" aria-label="Produktionssuche" placeholder="Titel der Ausstrahlung">
    </mat-form-field>
    <button aria-label="Suche ausführen" class="search-button" [attr.aria-hidden]="publicationList.showexpertsearch === true" [class.blurredout]="publicationList.showexpertsearch === true" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
  </form>

  <div class="expand-expertsearch-wrapper">
    <button [attr.aria-label]="publicationList.showexpertsearch ? 'Erweiterte Suche schließen' : 'Erweiterte Suche anzeigen'" class="expert-search-button" type="button" mat-stroked-button color="primary" (click)="publicationList.toggleExpertSearch()">
      {{publicationList.showexpertsearch ? 'Erweiterte Suche schließen' : 'Erweiterte Suche anzeigen'}}
    </button>

    <button class="expert-search-button" *ngIf="publicationList.showexpertsearch" type="button" mat-stroked-button color="primary" title="Expertensuche zurücksetzten" (click)="publicationList.resetExpertSearch(true)">
      zurücksetzen
    </button>
  </div>
</div>

<mat-accordion class="expertsearch-collapse">
  <mat-expansion-panel [expanded]="publicationList.showexpertsearch">
    <div class="expertsearch-wrapper">

      <form [formGroup]="publicationList.expertSearchForm" (ngSubmit)="publicationList.detailSearchPublications()">
        <mat-form-field>
          <input matInput placeholder="Ausspieltitel" name="playout_title" formControlName="playout_title">
        </mat-form-field>

        <mat-form-field class="broadcaster">
          <mat-label>nutzende Anstalt</mat-label>
          <mat-select [attr.role]="'selectbox'" name="broadcaster" name="broadcaster" formControlName="broadcaster" multiple>
            <mat-option *ngFor="let broadcaster of publicationList.broadcasters" [value]="broadcaster.id" [hidden]="broadcaster.is_streaming_broadcaster === 1">{{broadcaster.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="leading-broadcaster">
          <mat-label>Federführung</mat-label>
          <mat-select [attr.role]="'selectbox'" name="leading_broadcaster" name="leading_broadcaster" formControlName="leading_broadcaster" multiple>
            <mat-option *ngFor="let broadcaster of publicationList.broadcasters" [value]="broadcaster.id" [hidden]="broadcaster.is_streaming_broadcaster === 1">{{broadcaster.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="genre">
          <mat-label>Genre</mat-label>
          <mat-select [attr.role]="'selectbox'" name="genre" name="genre" formControlName="genre" multiple>
            <mat-option *ngFor="let singleGenre of publicationList.genres" [value]="singleGenre">{{singleGenre}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="start_datetime">
          <input matInput placeholder="Beginn ab" name="start_datetime" formControlName="start_datetime" [matDatepicker]="pickerStart">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <span class="points-divider">-</span>

        <mat-form-field class="end_datetime">
          <input matInput placeholder="Beginn bis" name="end_datetime" formControlName="end_datetime" [matDatepicker]="pickerEnd">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

        <div class="mat-form-field is_remunerated">
          <mat-checkbox name="is_remunerated" formControlName="is_remunerated" color="primary">Nur NV</mat-checkbox>
        </div>

        <button class="search-button" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
      </form>


    </div>
  </mat-expansion-panel>
</mat-accordion>
