import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ApiService} from '../_services/api.service';
import {AccountComponent} from '../account/account.component';
import {Confirmpassword} from '../_validators/confirmpassword';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  passwordchangedresponse;
  changepasswordform: FormGroup;
  submitted = false;
  isSaving = false;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('GVR-Tool | Passwort ändern');
    this.changepasswordform = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      newpasswordrepeat: ['', Validators.required, Confirmpassword.MatchPassword],
    });
  }

  updatePassword() {
    if (this.changepasswordform.invalid) {
      return;
    } else {
      this.isSaving = true;
    }
    this.apiService.updatePassword(this.changepasswordform.value).then(
      data => {
        this.passwordchangedresponse = data;
        this.submitted = true;
        this.isSaving = false;
        this.closeDialogSaving(true);
      },
    ).catch((error: any) => {
      this.submitted = false;
      this.isSaving = false;

      if (error) {
        this.snackBar.open('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      }
    });
  }

  closeDialogSaving(success: true | false) {
    this.dialogRef.close(success);
  }

}
