import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissioncheckerService {
  public userHasRight(permission: string) {
    return this.authenticationService.currentUserValue.allPermissions.filter(p => p.name === permission).length > 0;
  }

  public userHasRights(permissions: Array<string>): boolean {

    for(let index in permissions)
    {
      if(!this.userHasRight(permissions[index]))
      {
        return false;
      }
    }

    return true;
  }

  public userHasSomeRight(permissions: Array<string>): boolean {
    return this.authenticationService.currentUserValue.allPermissions.filter(p => permissions.includes(p.name)).length > 0;
  }
  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }
}
