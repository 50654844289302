import {Component, Input, Inject, OnInit, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../_services/api.service';
import {Broadcaster} from '../broadcaster';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Title} from '@angular/platform-browser';
import {Ruleset} from '../ruleset';

@Component({
  selector: 'app-calculator-button',
  templateUrl: './calculator-button.component.html'
})
export class CalculatorButtonComponent {
  thisproduction: object;
  @Input() private simulation: EventEmitter<boolean>;

  @Input() selectedproduction;
  @Input() isdetailview = false;

  constructor(public dialog: MatDialog) {
  }

  openDialog() {
    const dialogRef = this.dialog.open(CalculatorComponent, {
      width: '500px',
      data: {
        productiondata: this.selectedproduction,
        isdetailview: this.isdetailview,
        simulation: this.simulation
      }
    });
  }

}

interface onDestroy {
  ngOnDestroy(): void;
}

@Component({
  selector: 'app-calculator',
  templateUrl: 'calculator.component.html',
})

export class CalculatorComponent implements OnInit, onDestroy {
  calculationresult;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  calculationform: FormGroup;
  submitted = false;
  isSaving = false;
  showSpinnerOnSubmit = false;
  showSpinnerOnSimulate = false;
  rulesets: Ruleset[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CalculatorButtonComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public permissionchecker: PermissioncheckerService,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {}

  resetCalculation(): void {
    this.submitted = false;
    this.calculationresult = null;
  }

  calculatePoints() {
    if (this.calculationform.invalid) {
      return;
    } else {
      this.isSaving = true;
      this.showSpinnerOnSubmit = true;
    }

    this.apiService.postCalculation(this.calculationform.value).then(
      data => {
        this.calculationresult = data;
        this.submitted = true;
        this.isSaving = false;
        this.showSpinnerOnSubmit = false;
      }
    );
  }

  simulateBroadcast() {
    if (this.calculationform.invalid) {
      this.calculationform.markAllAsTouched();
      return;
    } else {
      this.isSaving = true;
      this.showSpinnerOnSimulate = true;
    }

    this.apiService.createBroadcast(this.calculationform.value).then(
      data => {
        this.snackBar.open('Simulation wurde gespeichert!', 'OK', {
          duration: 4000,
        });
        this.isSaving = false;
        this.showSpinnerOnSimulate = false;

        this.data.simulation.emit(true);
        this.dialogRef.close();
      }
    );
  }

  findRulesetByID(idToFind) {
    return this.rulesets.find(x=>x.id == idToFind);
  }

  ngOnInit(): void {
    this.titleService.setTitle('GVR-Tool | GVR-Rechner');
    // Needs an object in an array to work properly in cases of overview-call and detail-view-call.
    if(typeof this.data.productiondata[0] === 'undefined') {
      this.data.productiondata = [ this.data.productiondata ];
    }

    this.calculationform = this.formBuilder.group({
      production_id: [this.data.productiondata[0].id],
      startTime: ['', Validators.required],
      startDate: ['', Validators.required],
      broadcaster_id: ['', Validators.required],
    });

    if (this.permissionchecker.userHasRight('read-rulesets')) {
      this.apiService.getRulesets().subscribe((ruleset: Ruleset[]) => {
        this.rulesets = ruleset;
      });
    }
  }

  ngOnDestroy():void {
    this.titleService.setTitle('GVR-Tool | Produktions-Detailseite');
  }
}
