<div class="container-fluid container-fluid-padding" role="main">
  <div class="container">

    <button *ngIf="permissionchecker.userHasRight('read-productions')" mat-icon-button color="primary" aria-label="zurück zur Übersicht" routerLink="/">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="account-data" [hidden]="loaded === false">
      <div class="headline-container">
        <h1 class="headline"><span class="headline-description">Profil:</span>
          <mat-icon>account_circle</mat-icon>
          {{currentuser.name}} {{currentuser.lastName}}
        </h1>
      </div>

      <div class="account-data-form-container">
        <form [formGroup]="accountform" (ngSubmit)="updateAccountData()" class="account-data-form">

          <mat-form-field>
            <input name="email" type="email" matInput disabled placeholder="E-Mail" value="name@example.com" [value]="currentuser.email">
          </mat-form-field>

          <mat-form-field class="contains-password pb-4">
            <input type="password" matInput disabled value="ewerk rockt!" placeholder="Passwort">
            <button class="change-password" title="Passwort ändern" type="button" mat-button color="primary" (click)="openDialog()">ändern</button>
          </mat-form-field>

          <mat-form-field>
            <input name="firstname" formControlName="firstname" type="text" matInput placeholder="Vorname">
          </mat-form-field>

          <mat-form-field>
            <input name="lastname" formControlName="lastname" type="text" matInput placeholder="Nachname">
          </mat-form-field>

          <mat-form-field class="pb-3">
            <input name="phone" formControlName="phone" type="text" matInput placeholder="Telefon">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Anstalt</mat-label>
            <mat-select [attr.role]="'selectbox'" name="broadcaster" formControlName="broadcaster">
              <mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.id" [disabled]="broadcaster.is_production_company === 1">{{broadcaster.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input name="position" formControlName="position" type="text" matInput placeholder="Funktion / Abteilung">
          </mat-form-field>

          <div class="button-wrapper">
            <button [disabled]="isSaving" mat-raised-button color="primary">
              <mat-spinner color="primary" diameter="15" [hidden]="!isSaving"></mat-spinner>
              Speichern
            </button>
          </div>

        </form>
      </div>

    </div>

    <div class="account-data account-data-empty-container" [hidden]="loaded === true">
      <div class="spinner-center">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>
    </div>

  </div>
</div>
