import {Component, Input, OnInit} from '@angular/core';
import {ProductionlistComponent} from '../productionlist/productionlist.component';

@Component({
  selector: 'app-productionlist-search',
  templateUrl: './productionlist-search.component.html'
})
export class ProductionlistSearchComponent implements OnInit {

  constructor(
    public productionList: ProductionlistComponent,
  ) { }

  ngOnInit() {
  }
}
