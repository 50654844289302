import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../_services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {Role} from '../_models';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html'
})
export class RolesComponent implements OnInit {
  displayedColumns: string[] = ['id', 'displayname', 'description', 'show_details'];
  dataSource = new MatTableDataSource();
  roles; // : Role[]
  loading = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private apiService: ApiService,
    public permissionchecker: PermissioncheckerService,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle("GVR-Tool | Rollen");
    this.loading = true;
    this.apiService.getRoles().subscribe((roles) => {
      this.roles = roles;
      this.dataSource.data = roles;
      this.loading = false;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  refreshRoles(): void {
    this.apiService.getRoles().subscribe((roles) => { // : Role[]
      this.roles = roles;
      this.dataSource.data = roles;
      this.loading = false;
    });
  }

  showLoader(): void {
    this.loading = true;
  }
}
