<button *ngIf="!usertobeedited"
        matTooltip="Neuen User anlegen" [disabled]="!permissionchecker.userHasRight('create-users')"
        class="add-user-button" mat-raised-button color="primary" aria-label="User erzeugen" (click)="openDialog()">
  + Neuer User
</button>

<button
  *ngIf="usertobeedited && permissionchecker.userHasRight('update-users')" matTooltip="User bearbeiten"
  class="users-edit-button" mat-icon-button (click)="openDialog('edit')">
  <mat-icon>edit</mat-icon>
</button>

