<div class="container-fluid container-fluid-padding" role="main">
  <div class="container">

    <button *ngIf="permissionchecker.userHasRight('read-productions')" mat-icon-button color="primary" aria-label="zurück zur Übersicht" routerLink="/">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="rules-data" [hidden]="loaded === false">
      <div class="headline-container">
        <h1 class="headline"><span class="headline-description">Administration:</span>
          <mat-icon>menu_book</mat-icon>
          Regelwerke
        </h1>
      </div>

      <div class="ruleset-container">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let ruleset of rulesets" class="single-ruleset mat-elevation-z0 ruleset-id-{{ruleset.id}}">

            <mat-expansion-panel-header collapsedHeight="5rem" expandedHeight="5rem">
              <mat-panel-title>
                {{ruleset.name}}
                <mat-icon class="icon-active" matTooltip="Regelset aktiv" *ngIf="ruleset.active">check_circle</mat-icon>
                <mat-icon class="icon-inactive" matTooltip="Regelset inaktiv" *ngIf="!ruleset.active">highlight_off</mat-icon>

                <app-rules-edit-button [rulesettobeedited]="ruleset" (refreshrequest)="refreshRules();" (showloader)="showLoader();"></app-rules-edit-button>

              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="rule-general row">

              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="Gültig von" value="{{ruleset.valid_from | date: 'dd.MM.yyyy'}}">
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="Gültig bis" value="{{ruleset.valid_to | date: 'dd.MM.yyyy'}}">
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field>
                  <mat-label>Genre</mat-label>
                  <mat-select disabled [attr.role]="'selectbox'" name="genre" [(value)]="ruleset.genre">
                    <mat-option value="Spielfilm">Spielfilm</mat-option>
                    <mat-option value="Dokumentation">Dokumentation</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-3 center-checkbox">
                <mat-checkbox [checked]="ruleset.active" disabled>
                  <label>Aktiv</label>
                </mat-checkbox>
              </div>
            </div>

            <div class="rule-general row">
              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="GVR-ID" value="{{ruleset.gvr_id}}">
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="Startguthaben" value="{{ruleset.initial_points}} Punkte">
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="Erstvergütung" value="{{ruleset.initial_payment | number:'.2':'de'}}€">
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field>
                  <input matInput disabled placeholder="Kosten pro Punkt" value="{{ruleset.cost_per_point_in_euro}}€">
                </mat-form-field>
              </div>
            </div>

            <div class="rule-general row">

              <div class="col-3" *ngIf="ruleset.genre === 'Dokumentation'">
                <mat-form-field>
                  <input matInput disabled placeholder="Max. Ausstrahlungen bis Erschöpfung" value="{{ruleset.packet_end_by_publication_count === 0 ? '∞' : ruleset.packet_end_by_publication_count}}">
                </mat-form-field>
              </div>

              <div class="col-3" *ngIf="ruleset.genre === 'Dokumentation'">
                <mat-form-field>
                  <input matInput disabled placeholder="Mindestkosten Erstausstrahlung" value="{{ruleset.initial_publication_amount}}">
                </mat-form-field>
              </div>

            </div>

            <div class="rule-slots">
              <h2 class="cluster-headline">Sendeslots</h2>

              <div class="rule-single-slot row" *ngFor="let slot of ruleset.slots">

                <div class="col-6 col-md-3">
                  <mat-form-field>
                    <input matInput disabled placeholder="Slot-Beginn" value="{{slot.start | slice:0:-3 }}">
                  </mat-form-field>
                </div>

                <div class="col-6 col-md-3">
                  <mat-form-field>
                    <input matInput disabled placeholder="Slot-Ende" value="{{slot.end | slice:0:-3 }}">
                  </mat-form-field>
                </div>

                <div class="col-6 col-md-3">
                  <mat-form-field>
                    <input matInput disabled placeholder="Punktefaktor" [value]="slot.slot_factor">
                  </mat-form-field>
                </div>

                <div class="col-6 col-md-3 center-checkbox">
                  <mat-checkbox [checked]="slot.service_repetition_allowed" disabled>
                    <label>Wiederholungen erlaubt</label>
                  </mat-checkbox>
                </div>

              </div>
            </div>

            <div class="rule-broadcasters">
              <h2 class="cluster-headline">Basisfaktoren der Anstalten</h2>
              <div class="row broadcaster-list">
                <ng-container *ngFor="let broadcaster of ruleset.broadcasterBasicFactors">
                  <div class="single-broadcaster col-md-6 col-lg-4" *ngIf="broadcaster.broadcaster_name !== 'invalid'">
                    <div class="single-broadcaster-inner">

                      <label class="broadcaster-headline">{{broadcaster.broadcaster_name === 'invalid' ? 'undefined' : broadcaster.broadcaster_name}}</label>
                      <div class="basefactor-container">
                        <mat-form-field>
                          <input matInput disabled placeholder="Basisfaktor" [value]="broadcaster.basefactor">
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput disabled placeholder="NV-Faktor" [value]="broadcaster.basefactor_score_under_zero">
                        </mat-form-field>
                      </div>

                      <div class="validity-container">
                        <mat-form-field>
                          <input matInput name="valid_for_amount" disabled placeholder="Gültigkeit" [value]="broadcaster.valid_for_amount">
                        </mat-form-field>

                        <mat-select [attr.role]="'selectbox'" disabled name="valid_for_unit" [value]="broadcaster.valid_for_unit">
                          <mat-option value="Hour">Stunden</mat-option>
                          <mat-option value="Month">Monate</mat-option>
                          <mat-option value="Year">Jahre</mat-option>
                        </mat-select>
                      </div>

                      <mat-checkbox name="dateinterval_includes_sundays" [checked]="broadcaster.dateinterval_includes_sundays" disabled>
                        <label>Sonntage berücksichtigen</label>
                      </mat-checkbox>

                      <mat-checkbox name="dateinterval_includes_legal_holidays" [checked]="broadcaster.dateinterval_includes_legal_holidays" disabled>
                        <label>Feiertage berücksichtigen</label>
                      </mat-checkbox>

                      <mat-checkbox name="use_slot_factor" [checked]="broadcaster.use_slot_factor" disabled>
                        <label>Slot-Faktor berücksichten</label>
                      </mat-checkbox>

                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

        <div class="add-ruleset-button-container">

          <app-rules-edit-button (refreshrequest)="refreshRules();" (showloader)="showLoader();"></app-rules-edit-button>

        </div>
      </div>
    </div>

    <div class="rules-data rules-data-empty-container" [hidden]="loaded === true">
      <div class="spinner-center">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>
    </div>

  </div>
</div>
