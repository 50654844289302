<h1 mat-dialog-title>Passwort ändern</h1>

<form [formGroup]="changepasswordform" (ngSubmit)="updatePassword()">
  <div mat-dialog-content>

    <mat-form-field>
      <input name="oldpassword" formControlName="oldpassword" type="password" matInput placeholder="Altes Passwort">
    </mat-form-field>

    <mat-form-field>
      <input name="newpassword" formControlName="newpassword" type="password" matInput placeholder="Neues Passwort">
    </mat-form-field>

    <mat-form-field>
      <input name="newpasswordrepeat" formControlName="newpasswordrepeat" type="password" matInput placeholder="Neues Passwort wiederholen">
    </mat-form-field>

  </div>

  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="this.dialogRef.close()">abbrechen</button>
    <button mat-raised-button color="primary" cdkFocusInitial [disabled]="isSaving == true">
      <mat-spinner color="primary" diameter="15" [hidden]="isSaving === false"></mat-spinner>
      Passwort ändern
    </button>
  </div>

</form>
