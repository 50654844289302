import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Broadcaster} from '../broadcaster';
import {Production, ProductionPayload} from '../production';
import {DisplaynametonamepipePipe} from '../_pipes/displaynametonamepipe.pipe';
import {environment} from '../../environments/environment';
import {Ruleset} from '../ruleset';
import {Role, User} from '../_models';
import {Permission} from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL = environment.apiHostProtocol + (environment.apiHost.length > 0 ? environment.apiHost : window.location.hostname) + environment.apiPort + environment.apiPath;

  constructor(private httpClient: HttpClient) {
  }

  public getBroadcasters(token?: string) {
    if (token) {
      // Case on Login: Interceptor does not work yet, manual token required for write a complete set of user-data to local Storage
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
      return this.httpClient.get<Broadcaster[]>(`${this.apiURL}/get/broadcaster`, {headers: reqHeader});
    } else {
      return this.httpClient.get<Broadcaster[]>(`${this.apiURL}/get/broadcaster`);
    }
  }

  public getBroadcasterById(id: number) {
    return this.httpClient.get<Broadcaster>(`${this.apiURL}/get/broadcaster/${id}`);
  }

  public getStreamingBroadcasters(token?: string) {
    if (token) {
      // Case on Login: Interceptor does not work yet, manual token required for write a complete set of user-data to local Storage
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
      return this.httpClient.get<Broadcaster[]>(`${this.apiURL}/get/streamingBroadcaster`, {headers: reqHeader});
    } else {
      return this.httpClient.get<Broadcaster[]>(`${this.apiURL}/get/streamingBroadcaster`);
    }
  }

  public getStreamingBroadcasterById(id: number) {
    return this.httpClient.get<Broadcaster>(`${this.apiURL}/get/streamingBroadcaster/${id}`);
  }

  public getProductions() {
    return this.httpClient.get<Production[]>(`${this.apiURL}/get/production`);
  }

  public getProductionById(id: number) {
    return this.httpClient.get<ProductionPayload>(`${this.apiURL}/get/production/${id}`);
  }

  public getPublications() {
    return this.httpClient.get<Production[]>(`${this.apiURL}/get/publication`);
  }

  public getRulesets() {
    return this.httpClient.get<Ruleset[]>(`${this.apiURL}/get/ruleset`);
  }

  public getRulesetById(id: number) {
    return this.httpClient.get<Ruleset>(`${this.apiURL}/get/ruleset/${id}`);
  }

  public getCurrentUser(token?: string) { // currentUser
    if (token) {
      // Case on Login: Interceptor does not work yet, manual token required for write a complete set of user-data to local Storage
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
      return this.httpClient.get<User[]>(`${this.apiURL}/get/currentUser`, {headers: reqHeader});
    } else {
      return this.httpClient.get<User[]>(`${this.apiURL}/get/currentUser`);
    }
  }

  public getUsers() {
    return this.httpClient.get<User[]>(`${this.apiURL}/get/user`);
  }

  public getUserById(id: number) {
    return this.httpClient.get<User>(`${this.apiURL}/get/user/${id}`);
  }

  public getRoles() {
    return this.httpClient.get<Role[]>(`${this.apiURL}/get/role`);
  }

  public getRoleById(id: number) {
    return this.httpClient.get<Role>(`${this.apiURL}/get/role/${id}`);
  }

  public getPermissions() {
    return this.httpClient.get<Permission[]>(`${this.apiURL}/get/permission`);
  }

  public getGenres() {
    return this.httpClient.get<Permission[]>(`${this.apiURL}/get/genre`);
  }

  public postCalculation(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/calculatePoints',
        {
          production_id: data.production_id,
          startTime: data.startTime,
          startDate: data.startDate,
          broadcaster_id: data.broadcaster_id
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public searchProductions(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/search',
        {
          page: data.pageNumber,
          pageSize: data.pageSize,
          sortOrder: data.sortOrder,
          searchstring: data.search.data.simpleSearchInput !== undefined ? data.search.data.simpleSearchInput : data.search.data,
          orderBy: [{field: data.sortField, direction: data.sortOrder}],
        }).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public detailSearchProductions(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/detailSearch',
        {
          page: data.pageNumber,
          pageSize: data.pageSize,
          sortOrder: data.sortOrder,
          title: data.search.data.title,
          broadcaster: data.search.data.broadcaster,
          genre: data.search.data.genre,
          year: data.search.data.year,
          score_min: data.search.data.score_min,
          score_max: data.search.data.score_max,
          rules: data.search.data.rules,
          orderBy: [{field: data.sortField, direction: data.sortOrder}],
        }).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public searchPublications(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/searchBroadcasts',
        {
          page: data.pageNumber,
          pageSize: data.pageSize,
          sortOrder: data.sortOrder,
          searchstring: data.search.data.simpleSearchInput !== undefined ? data.search.data.simpleSearchInput : data.search.data,
          orderBy: [{field: data.sortField, direction: data.sortOrder}],
        }).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public detailSearchPublications(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/detailSearchBroadcasts',
        {
          page: data.pageNumber,
          pageSize: data.pageSize,
          sortOrder: data.sortOrder,
          playout_title: data.search.data.playout_title,
          broadcaster: data.search.data.broadcaster,
          leading_broadcaster: data.search.data.leading_broadcaster,
          genre: data.search.data.genre,
          duration_minutes: data.search.data.duration_minutes,
          start_datetime: data.search.data.start_datetime,
          end_datetime: data.search.data.end_datetime,
          is_remunerated: data.search.data.is_remunerated,
          orderBy: [{field: data.sortField, direction: data.sortOrder}],
        }).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public createBroadcast(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/createBroadcast',
        {
          production_id: data.production_id,
          startTime: data.startTime,
          startDate: data.startDate,
          broadcaster_id: data.broadcaster_id
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public createRuleset(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/createRuleSet',
        {
          data
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public updateRuleset(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updateRuleSet',
        {
          data
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public deleteRuleset(rulesetid) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/deleteRuleSet',
        {
          ruleset_id: rulesetid,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public createUser(data) {

    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/checkMail',
      {
        email: data.email,
      }).subscribe(
      response => {
        // E-Mail is free
        this.httpClient.post(this.apiURL + '/post/register',
        {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          broadcaster_id: (data.broadcaster_id !== 'none' ? data.broadcaster_id : null),
          company: (data.company ? data.company : ''),
          roles: data.roles,
          phone: data.phone,
          department: data.department,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject("Es ist ein Fehler aufgetreten.");
        }
        );
      },
      error => {
        reject("Die E-Mail-Adresse wird bereits verwendet!");
      }
    );
  });

/*
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/register',
        {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          broadcaster_id: (data.broadcaster_id !== 'none' ? data.broadcaster_id : null),
          company: (data.company ? data.company : ''),
          roles: data.roles,
          phone: data.phone,
          department: data.department,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
    */
  }

  public updateUser(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updateUser',
        {
          firstname: data.firstname,
          lastname: data.lastname,
          broadcaster_id: (data.broadcaster_id !== 'none' ? data.broadcaster_id : null),
          company: (data.company ? data.company : ''),
          roles: data.roles,
          phone: data.phone,
          department: data.department,
          userid: data.userid,
    }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public deleteUser(userid) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/deleteUser',
        {
          userid,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public updateAccount(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updateUser',
        {
          firstname: data.firstname,
          lastname: data.lastname,
          phone: data.phone,
          broadcaster_id: data.broadcaster,
          department: data.position,
          userid: JSON.parse(localStorage.getItem('currentUser')).id,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public createRole(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/createRole',
        {
          role_name: data.role_name,
          role_display_name: data.role_display_name,
          role_description: data.role_description,
          permissions: data.permissions,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public updateRole(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updateRole',
        {
          role_id: data.role_id,
          role_name: data.role_name,
          role_display_name: data.role_display_name,
          role_description: data.role_description,
          permissions: data.permissions,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public deleteRole(roleid) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/deleteRole',
        {
          roleid,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public deleteBroadcast(broadcastid) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/deleteBroadcast',
        {
          broadcastid,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public updateProduction(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updateProduction',
        {
          production_id: data.production_id,
          leading_broadcaster_id: data.leading_broadcaster_id,
          editorial_broadcaster_id: data.editorial_broadcaster_id,
          ruleset_ids: data.ruleset_ids,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public updatePassword(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/updatePassword',
        {
          userid: JSON.parse(localStorage.getItem('currentUser')).id,
          password: data.newpassword,
          c_password: data.newpasswordrepeat,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public resetPassword(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiURL + '/post/resetPassword',
        {
          email: data.email,
        }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
