<div class="role-data-form">
  <h1 mat-dialog-title *ngIf="data.mode === 'create'">Neue Rolle anlegen</h1>
  <h1 mat-dialog-title *ngIf="data.mode === 'edit'">Rolle bearbeiten ({{data.role.name }})</h1>

  <form [formGroup]="roleseditform" (ngSubmit)="postRole(data.mode)">

    <div mat-dialog-content>

      <div class="row">

        <div class="col-sm-6">
          <mat-form-field>
            <input name="role_display_name" formControlName="role_display_name" matInput placeholder="Anzeigename der Rolle" #role_display_name (input)="convertToDisplay(role_display_name.value)">
          </mat-form-field>

          <mat-form-field class="filled-external">
            <input name="role_name" formControlName="role_name" readonly matInput placeholder="Name der Rolle" [value]="formconvertedname">
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-form-field>
            <textarea class="overflow-hidden" name="role_description" [formControl]="role_description" matInput matTextareaAutosize [matAutosizeMinRows]="5" placeholder="Beschreibung"></textarea>
            <mat-error *ngIf="role_description.hasError('required')">Die Beschreibung ist zwingend erforderlich</mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="row permission-list" [hidden]="loading === true">
        <div class="col-md-6 single-permission" formArrayName="permissions" *ngFor="let permission of rolesEditFormData.controls; let i = index;">
          <div class="single-permission-inner" [formGroupName]="i">
            <mat-slide-toggle matTooltip="{{permission.value.permission_name.substr(0, 4).toLowerCase() === 'read' ? 'Für Betrieb empfohlen. Die Felder >>Read Productions<< und >>Read Broadcaster<< sind für einen fehlerfreien Betrieb nötig.' : ''}}" color="primary" formControlName="permission_is_active" [checked]="permission.value.permission_is_active">{{permission.value.permission_display_name}}</mat-slide-toggle>
            <input type="hidden" formControlName="permission_name" [value]="permission.value.permission_name">
          </div>
        </div>
      </div>

    </div>

    <div mat-dialog-actions>
      <button mat-button color="primary" (click)="closeDialogSaving(false)">abbrechen</button>
      <button mat-raised-button color="primary" [disabled]="isSaving">
        <mat-spinner color="primary" diameter="15" [hidden]="isSaving === false"></mat-spinner>
        <span *ngIf="data.mode === 'create'">Rolle anlegen</span>
        <span *ngIf="data.mode === 'edit'">Änderungen abspeichern</span>
      </button>
    </div>
  </form>

  <div class="loading-spinner" *ngIf="loading === true">
    <mat-spinner color="primary" diameter="75"></mat-spinner>
  </div>
</div>
