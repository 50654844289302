import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {first} from 'rxjs/operators';
import {ApiService} from '../_services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment.prod';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  showLoginForm = true;
  showResetPassword = false;
  environment: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.environment = environment;
    this.titleService.setTitle("GVR-Tool | Login");
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  showResetPasswordView() {
    this.showResetPassword = true;
    this.showLoginForm = false;
  }

  showLoginFormView() {
    this.showResetPassword = false;
    this.showLoginForm = true;
  }

  resetPassWord() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.apiService.resetPassword(this.resetPasswordForm.value).then(
      data => {
        this.snackBar.open('Es wurde eine E-Mail mit einem neuen Passwort versendet. Bitte prüfen Sie Ihr Postfach.', 'OK', {
          duration: 10000,
        });
        this.loading = false;
      },
    ).catch((error: any) => {
      if (error) {
        this.snackBar.open('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'OK', {
          duration: 8000,
        });
        console.log(error);
        this.loading = false;
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.showResetPassword = false;
          this.loading = false;
        },
        error => {
          this.showResetPassword = false;
          this.error = error;
          this.loading = false;
        });
  }
}
