import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';

// Layouts
import {LoginComponent} from './login/login.component';
import {StandardComponent} from './_layouts/standard/standard.component';
import {NavigationComponent} from './_layouts/navigation/navigation.component';
import {ProductionlistComponent} from './productionlist/productionlist.component';
import {RulesComponent} from './rules/rules.component';
import {ProductionComponent} from './production/production.component';
import {CalculatorButtonComponent, CalculatorComponent} from './calculator/calculator.component';
import {RulesEditButtonComponent, RulesEditComponent} from './rules-edit/rules-edit.component';
import {UsermanagementComponent} from './usermanagement/usermanagement.component';
import {UserEditComponent} from './user-edit/user-edit.component';
import {UserEditButtonComponent} from './user-edit/user-edit.component';
import {AccountComponent} from './account/account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

// Globals
import {Globals} from './globals';

// Providers
import {getGermanPaginatorIntl} from './_providers/german-paginator-intl';
import {getGermanSortHeaderIntl} from './_providers/german-sort-header-intl';
import {getGermanDatepickerIntl} from './_providers/german-datepicker-intl';

// used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from './_helpers';

// Modules
import {MatSliderModule} from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatSortHeaderIntl, MatSortModule} from '@angular/material/sort';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RolesComponent } from './roles/roles.component';
import {RolesEditButtonComponent, RolesEditComponent} from './roles-edit/roles-edit.component';
import { DisplaynametonamepipePipe } from './_pipes/displaynametonamepipe.pipe';
import {HelpButtonComponent, HelpComponent} from './help/help.component';
import {SupportButtonComponent, SupportComponent} from './support/support.component';
import { ProductionlistSearchComponent } from './productionlist-search/productionlist-search.component';
import { PublicationlistComponent } from './publicationlist/publicationlist.component';
import { PublicationlistSearchComponent } from './publicationlist-search/publicationlist-search.component';

const modules = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  MatSliderModule,
  MatFormFieldModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  FormsModule,
  MatDialogModule,
  MatSelectModule,
  HttpClientModule,
  MatExpansionModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSlideToggleModule
];

const declarations = [
  AppComponent,
  StandardComponent,
  LoginComponent,
  ProductionlistComponent,
  NavigationComponent,
  RulesComponent,
  ProductionComponent,
  CalculatorComponent,
  CalculatorButtonComponent,
  RulesEditComponent,
  RulesEditButtonComponent,
  UsermanagementComponent,
  AccountComponent,
  ChangePasswordComponent,
  UserEditComponent,
  UserEditButtonComponent,
  RolesComponent,
  RolesEditComponent,
  RolesEditButtonComponent,
  DisplaynametonamepipePipe,
  HelpComponent,
  HelpButtonComponent,
  SupportComponent,
  SupportButtonComponent
];

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    declarations,
    ProductionlistSearchComponent,
    PublicationlistComponent,
    PublicationlistSearchComponent
  ],
  imports: [
    modules
  ],
  exports: [
    declarations,
    modules
  ],
  entryComponents: [
    CalculatorComponent,
    RulesEditComponent,
    RolesEditComponent,
    UserEditComponent,
    ChangePasswordComponent,
    HelpComponent,
    SupportComponent,
  ],
  providers: [
    {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()},
    {provide: MatSortHeaderIntl, useValue: getGermanSortHeaderIntl()},
    {provide: MatDatepickerIntl, useValue: getGermanDatepickerIntl()},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},

    MatDatepickerModule,
    Globals,
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
