<div class="container-fluid container-fluid-padding"  role="main" aria-label="Übersicht der Produktionen">
  <div class="container position-relative">

    <app-productionlist-search></app-productionlist-search>

    <div class="productions-data">
      <table mat-table [dataSource]="dataSource" matSort [class.blurredout]="(dataSource.loading$ | async) || loading === true" aria-label="Tabelle mit Produktionen">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Titel </th>
          <td mat-cell *matCellDef="let row"> {{row.title}}{{row.sub_title && row.sub_title !== 'null' ? ' - ' + row.sub_title : ''}}</td>
        </ng-container>

        <ng-container matColumnDef="brid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> creationBRID </th>
          <td mat-cell *matCellDef="let row"> {{row.brid}} </td>
        </ng-container>

        <ng-container matColumnDef="genre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Genre </th>
          <td mat-cell *matCellDef="let row"> {{row.genre}} </td>
        </ng-container>

        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Erstsendung </th>
          <td mat-cell *matCellDef="let row"> {{row.year}} </td>
        </ng-container>

        <ng-container matColumnDef="lra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Federführung </th>
          <td mat-cell *matCellDef="let row"> {{broadcasterNames ? broadcasterNames[row.leading_broadcaster_id] : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Herkunft </th>
          <td mat-cell *matCellDef="let row"> {{row.destination}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Art </th>
          <td mat-cell *matCellDef="let row"> {{row.type}} </td>
        </ng-container>

        <ng-container matColumnDef="current_score">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Guthaben </th>
          <td mat-cell *matCellDef="let row">
            <div class="d-block single-score" *ngFor="let singleScore of row.scores">
              <span *ngIf="singleScore.current_score >= 0">{{singleScore.current_score  | number:'.1-1':'de'}}</span>
              <span *ngIf="singleScore.current_score < 0" class="renumeration" matTooltip="Nachvergütet">N</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ruleset">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Regelwerke </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="rules.length > 0">
              <div class="d-block ruleset-border-bottom" *ngFor="let singleRuleset of row.ruleset_ids">
                {{findRulesetByID(singleRuleset).name}}
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Erstellt"> Erstellt </th>
          <td mat-cell *matCellDef="let row">
            {{row.created_at | date:'dd.MM.yyyy'}}
            <span>{{row.created_at | date:'HH:mm'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="initial_gvr_creation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="GVR-Eingang"> GVR-Eingang </th>
          <td mat-cell *matCellDef="let row">{{row.initial_gvr_creation | date:'dd.MM.yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="created_by_user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Erstellt von"> Erstellt von </th>
          <td mat-cell *matCellDef="let row">
            {{row.created_by_user_id}}
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Aktualisiert"> Aktualisiert </th>
          <td mat-cell *matCellDef="let row">
            {{row.updated_at | date:'dd.MM.yyyy'}}
            <span>{{row.updated_at | date:'HH:mm'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_by_user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Aktualisiert von"> Aktualisiert von </th>
          <td mat-cell *matCellDef="let row"> {{row.updated_by_user_id}} </td>
        </ng-container>

        <ng-container matColumnDef="show_details">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"> <mat-icon aria-hidden="false" title="Produktion anzeigen" color="primary">info</mat-icon> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr style="cursor: pointer"
            routerLink="/production/{{row.id}}"
            mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered, selected: selection.isSelected(row)}"
            (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false"
            (keydown.enter)="routingToProduction(row.id)"
            (keydown.space)="routingToProduction(row.id)">
        </tr>
      </table>

      <div class="table-loading-container" *ngIf="(dataSource.loading$ | async) || loading === true">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>

      <div class="table-empty-container" *ngIf="paginator.length === 0 && !(dataSource.loading$ | async)">
        <div class="empty-message">
          <strong>Keine Ergebnisse</strong>
          <span>Bitte überprüfen Sie Ihre Suche.</span>
        </div>

      </div>

      <div class="excel-export-container">
        <mat-icon matTooltip="Excel-Export" aria-hidden="false" aria-label="Excel-Export" (click)="exportProductions()">file_download</mat-icon>
      </div>

      <mat-paginator aria-label="Seitenauswahl" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
    <app-support-button></app-support-button>
    <app-help-button></app-help-button>

  </div>
</div>
