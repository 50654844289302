<div class="container-fluid container-fluid-padding" role="main">
  <div class="container position-relative">

    <button mat-icon-button color="primary" aria-label="zurück zur Übersicht" routerLink="/">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <form class="production-data" [formGroup]="productionform" (ngSubmit)="updateProduction()" [hidden]="loaded === false">

      <div class="headline-container production-headline">
        <h1 class="headline">
          <span class="headline-description">Produktion:</span>
          {{currentproduction.title}}{{currentproduction.sub_title && currentproduction.sub_title !== "null" ? ' - ' + currentproduction.sub_title : ''}}
        </h1>
        <div>
          <button *ngIf="this.permissionchecker.userHasRight('create-broadcast')" mat-raised-button color="primary" [disabled]="isSaving === true">
            <mat-spinner color="primary" diameter="15" [hidden]="isSaving === false"></mat-spinner>
            Änderungen abspeichern
          </button>
        </div>
      </div>

      <div class="row production-details-container">

        <div class="col-8 production-details">
          <div class="row">

            <div class="col-6 production-field">
              <mat-form-field>
                <input matInput disabled placeholder="Titel" [value]="currentproduction.title">
              </mat-form-field>
            </div>

            <div class="col-6 production-field">
              <mat-form-field>
                <input matInput disabled placeholder="Episodentitel" [value]="currentproduction.sub_title !== 'null' ? currentproduction.sub_title : ''">
              </mat-form-field>
            </div>

            <div class="col-6 production-field">
              <mat-form-field>
                <mat-label>Federführende LRA</mat-label>
                <mat-select [attr.role]="'selectbox'" disabled name="leading_broadcaster_id" formControlName="leading_broadcaster_id" [value]="currentproduction.leading_broadcaster_id">
                  <mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.id">{{broadcaster.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6 production-field">
              <mat-form-field>
                <mat-label>Redaktionelle Führung</mat-label>
                <mat-select [attr.role]="'selectbox'" name="editorial_broadcaster_id" formControlName="editorial_broadcaster_id" [value]="currentproduction.editorial_broadcaster_id">
                  <mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.id">{{broadcaster.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6 production-field">
              <mat-form-field>
                <mat-label>GVR-Regeln</mat-label>
                <mat-select [attr.role]="'selectbox'" multiple name="ruleset_ids" formControlName="ruleset_ids" [value]="currentproduction.ruleset_ids">
                  <ng-container *ngFor="let ruleset of rulesets">
                    <mat-option *ngIf="currentproduction.genre === ruleset.genre" [value]="ruleset.id" [disabled]="ruleset.active !== 1">{{ruleset.name}}<span *ngIf="ruleset.active !== 1"> (inaktiv)</span></mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6 production-field">
              <mat-form-field>
                <input matInput disabled placeholder="creationBRID" [value]="currentproduction.brid">
              </mat-form-field>
            </div>

          </div>
        </div>

        <div class="col-4 production-details">
          <div class="row">
            <div class="col production-field">
              <mat-form-field>
                <input matInput disabled placeholder="Genre" [value]="currentproduction.genre">
              </mat-form-field>
            </div>

            <div class="col production-field">
              <mat-form-field>
                <input matInput disabled placeholder="Erstausstrahlung" [value]="productionfirstbroadcast">
              </mat-form-field>
            </div>

            <div class="col production-field">
              <mat-form-field>
                <input matInput disabled placeholder="GVR-Eingang" [value]="currentproduction.initial_gvr_creation | date:'dd.MM.yyyy'">
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
      <div class="headline-container">
        <h2 class="subheadline" *ngIf="scores.length === 1">Punktestand</h2>
        <h2 class="subheadline" *ngIf="scores.length > 1">Punktestände</h2>
      </div>
      <div class="production-balance-container">
        <div class="row production-balance">
          <table>
            <thead>
              <tr>
                <th class="label">Paketname</th>
                <th class="label">Startguthaben</th>
                <th class="label">bereits verbraucht</th>
                <th class="label">aktuelles Guthaben</th>
                <th class="label">voraussichtliches Guthaben</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let score of scores; let i=index;">
                <tr>
                  <td class="points-value points-value--name" [ngClass]="{'points-value-padding-top':  i != 0}">
                    {{ score.rule_name }}
                  </td>
                  <td class="points-value" [ngClass]="{'points-value-padding-top':  i != 0}">
                    {{ score.init_value | number:'.1-1':'de' }}
                  </td>
                  <td class="points-value" [ngClass]="{'points-value-padding-top':  i != 0}">
                    {{ score.used_points | number:'.1-1':'de' }}
                  </td>
                  <td class="points-value" [ngClass]="{'points-value-padding-top':  i != 0}">
                    {{ score.current_score | number:'.1-1':'de' }}
                  </td>
                  <td class="points-value" [ngClass]="{'points-value-padding-top':  i != 0}">
                    {{ score.future_score | number:'.1-1':'de' }}
                  </td>
                </tr>
                <tr class="points-table-footer">
                  <td class="points-label"></td>
                  <td class="points-label">Punkte</td>
                  <td class="points-label">Punkte</td>
                  <td class="points-label">Punkte</td>
                  <td class="points-label">Punkte</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="headline-container">
        <h2 class="subheadline">Publikationen</h2>
      </div>

      <div class="publications-details-container">

        <div class="publications-details-past">
          <h2 class="table-headline">Vergangene Sendungen</h2>
          <span class="no-publications-until-now" [hidden]="pastpublications.filteredData.length > 0">Keine vergangenen Publikationen</span>
          <table mat-table #pastpublicationstable [dataSource]="pastpublications" [hidden]="pastpublications.filteredData.length === 0" matSort matSortActive="start" matSortDirection="desc">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Nummer</th>
              <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt am</th>
              <td mat-cell *matCellDef="let row">
                {{row.created_at | date:'dd.MM.yyyy'}}
                <span>{{row.created_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert am</th>
              <td mat-cell *matCellDef="let row">
                {{row.updated_at | date:'dd.MM.yyyy'}}
                <span>{{row.updated_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Beginn</th>
              <td mat-cell *matCellDef="let row">
                {{row.start | date:'dd.MM.yyyy'}}<br>
                <span>{{row.start | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="stop">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ende</th>
              <td mat-cell *matCellDef="let row">
                {{row.stop | date:'dd.MM.yyyy'}}<br>
                <span>{{row.stop | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="duration_minutes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Länge">Länge</th>
              <td mat-cell *matCellDef="let row"> {{row.duration_minutes}}  min. </td>
            </ng-container>

            <ng-container matColumnDef="broadcaster_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>nutzende Anstalt</th>
              <td mat-cell *matCellDef="let row"> {{row.broadcaster_name}} </td>
            </ng-container>

            <ng-container matColumnDef="playout_title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ausspieltitel</th>
              <td mat-cell *matCellDef="let row">
                {{row.playout_title ? row.playout_title : currentproduction.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="ruleset_applied">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Regelset</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.ruleset_applied}}:
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="used_points">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Verbrauch</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.amount ? singleTransaction.value.amount * -1 : 0}} Punkte
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="transaction_cost">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Kosten (NV)</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.cost ? singleTransaction.value.cost + "€" : "0"}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row"> <mat-icon color="warn" [hidden]="row.is_simulated === 0" (click)="deleteBroadcast(row.id, 'past')">delete</mat-icon> </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{hovered: row.hovered, 'is-under-zero': row.is_under_zero}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>
          </table>
        </div>

        <div class="publications-details-future">
          <h2 class="table-headline">Anstehende Sendungen</h2>
          <span class="no-publications-until-now" [hidden]="futurepublications.filteredData.length > 0">Keine anstehenden Publikationen</span>
          <table mat-table #futurepublicationstable [dataSource]="futurepublications" [hidden]="futurepublications.filteredData.length === 0" matSort matSortActive="start" matSortDirection="desc">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Nummer</th>
              <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt am</th>
              <td mat-cell *matCellDef="let row">
                {{row.created_at | date:'dd.MM.yyyy'}}
                <span>{{row.created_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert am</th>
              <td mat-cell *matCellDef="let row">
                {{row.updated_at | date:'dd.MM.yyyy'}}
                <span>{{row.updated_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Beginn</th>
              <td mat-cell *matCellDef="let row">
                {{row.start | date:'dd.MM.yyyy'}}<br>
                <span>{{row.start | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="stop">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ende</th>
              <td mat-cell *matCellDef="let row">
                {{row.stop | date:'dd.MM.yyyy'}}<br>
                <span>{{row.stop | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="duration_minutes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Länge</th>
              <td mat-cell *matCellDef="let row"> {{row.duration_minutes}} min. </td>
            </ng-container>

            <ng-container matColumnDef="broadcaster_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>nutzende Anstalt</th>
              <td mat-cell *matCellDef="let row"> {{row.broadcaster_name}} </td>
            </ng-container>

            <ng-container matColumnDef="playout_title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ausspieltitel</th>
              <td mat-cell *matCellDef="let row">
                {{row.playout_title ? row.playout_title : currentproduction.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="ruleset_applied">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Regelset</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.ruleset_applied}}:
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="used_points">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Verbrauch</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.amount ? singleTransaction.value.amount * -1 : 0}} Punkte
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="transaction_cost">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Kosten (NV)</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.cost ? singleTransaction.value.cost + "€" : "0"}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row"> <mat-icon color="warn" [hidden]="row.is_simulated === 0" (click)="deleteBroadcast(row.id, 'future')">delete</mat-icon> </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{hovered: row.hovered, 'is-under-zero': row.is_under_zero}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>
          </table>
        </div>

        <div class="publications-details-media">
          <h2 class="table-headline">Mediatheknutzungen
            <button *ngIf="this.permissionchecker.userHasRight('create-broadcast') && streamingpublications.data.length > 0" mat-stroked-button color="primary" (click)="add100kviews()" [disabled]="isSavingViews === true">
              <mat-spinner color="primary" diameter="15" [hidden]="isSavingViews === false"></mat-spinner>
              + 100.000 Views buchen
            </button>
          </h2>
          <span class="no-publications-until-now" [hidden]="streamingpublications.filteredData.length > 0">Keine aktuelle Mediatheknutzung</span>

          <table mat-table #streamingpublicationstable [dataSource]="streamingpublications" [hidden]="streamingpublications.filteredData.length === 0" matSort matSortActive="start" matSortDirection="desc">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Nummer</th>
              <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt am</th>
              <td mat-cell *matCellDef="let row">
                {{row.created_at | date:'dd.MM.yyyy'}}
                <span>{{row.created_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert am</th>
              <td mat-cell *matCellDef="let row">
                {{row.updated_at | date:'dd.MM.yyyy'}}
                <span>{{row.updated_at | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Beginn</th>
              <td mat-cell *matCellDef="let row">
                {{row.start | date:'dd.MM.yyyy'}}<br>
                <span>{{row.start | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="stop">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ende</th>
              <td mat-cell *matCellDef="let row">
                {{row.stop | date:'dd.MM.yyyy'}}<br>
                <span>{{row.stop | date:'HH:mm'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="duration_minutes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Länge</th>
              <td mat-cell *matCellDef="let row"> {{row.duration_minutes}} min. </td>
            </ng-container>

            <ng-container matColumnDef="broadcaster_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>nutzende Anstalt</th>
              <td mat-cell *matCellDef="let row"> {{row.broadcaster_name}} </td>
            </ng-container>

            <ng-container matColumnDef="playout_title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ausspieltitel</th>
              <td mat-cell *matCellDef="let row">
                {{row.playout_title ? row.playout_title : currentproduction.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="ruleset_applied">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Regelset</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.ruleset_applied}}:
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="used_points">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Verbrauch</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.amount ? singleTransaction.value.amount * -1 : 0}} Punkte
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="transaction_cost">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Kosten (NV)</th>
              <td mat-cell *matCellDef="let row">
                <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
                  {{singleTransaction.value.cost ? singleTransaction.value.cost + "€" : "0"}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row"> <mat-icon color="warn" [hidden]="row.is_simulated === 0" (click)="deleteBroadcast(row.id, 'streaming')">delete</mat-icon> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsStreaming"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsStreaming;"
                [ngClass]="{hovered: row.hovered, 'is-under-zero': row.is_under_zero}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>
          </table>

        </div>

      </div>

    </form>

    <div class="production-data production-data-empty-container" [hidden]="loaded == true">

      <mat-spinner color="primary" diameter="75" *ngIf="loaded === false"></mat-spinner>

      <div class="production-data-empty-inner" *ngIf="loaded === true">
        <h1 class="production-data-empty-headline">Produktion nicht gefunden</h1>
        <strong class="production-data-empty-description">Die von Ihnen angeforderte Produktion konnte leider nicht
          gefunden werden.</strong>
      </div>
    </div>
    <app-support-button></app-support-button>
    <app-help-button [hidden]="!currentproduction.id && loaded === false"></app-help-button>

  </div>
</div>

<app-calculator-button *ngIf="permissionchecker.userHasRight('read-calculator')" [simulation]="simulation" [selectedproduction]="currentproduction" [isdetailview]="true" [ngClass]="{ 'no-selection': loaded == false}"></app-calculator-button>
