import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'displaynametonamepipe'
})
export class DisplaynametonamepipePipe implements PipeTransform {

  transform(value: string): string {
    value = value.replace(/[^A-Z0-9]+/ig, '').toLowerCase();
    return value;
  }

}
