import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Globals} from '../../globals';

import { AuthenticationService } from '../../_services';
import {Role, User} from '../../_models';
import {PermissioncheckerService} from '../../_services/permissionchecker.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  currentUser: User;
  environment: any;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    public permissionchecker: PermissioncheckerService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.environment = environment;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
