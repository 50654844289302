import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html'
})
export class StandardComponent implements OnInit {
  environment: any;
  constructor() { }

  ngOnInit() {
    this.environment = environment;
  }

}
