const dictionary = {
  created_at: 'erstellt am',
  updated_at: 'geändert am',
  title: 'Titel',
  sub_title: 'Episodentiel',
  year: 'Jahr',
  leading_broadcaster_id: 'federführende Rundfunkanstalt',
  editorial_broadcaster_id: 'redaktionelle Führung',
  used_points: 'Verbrauch',
  broadcaster_name: 'Anstalt',
  playout_title: 'Ausspieltitel',
  transaction_cost: 'Kosten bei Nachvergütung',
  current_score: 'Punkteguthaben',
  roles: 'Rollen',
  broadcaster_id: 'Anstalt',
  lastname: 'Nachname',
  firstname: 'Vorname',
  displayname: 'Name'
};

/**
 * Übersetz die normalen attribute in deutsche Wörter.
 * Grund hierfür ist zum Beispiel der Sortiertitel, welcher durch das attribute dargestellt wird,
 * was nicht barrierefrei ist, da die attribute auf englisch sind.
 */
export function translateAttributeToLabel(attribute): string {
  for (const property in dictionary) {
    if (property === attribute) {
      return dictionary[property];
    }
  }

  return attribute;
}
