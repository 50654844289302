<h1 mat-dialog-title>Support</h1>
<strong class="mat-dialog-subtitle">
  <span class="dialog-description">Bei technischen Problemen oder Fragen zur Anwendung wenden Sie sich bitte an den Servicedesk</span>
</strong>

<div mat-dialog-content>
  <div class="contact-data">
    <span>
      Email ARD Zentraler Servicedesk:<br>
      <a href="mailto:support@ardzsd.de">support@ardzsd.de</a>
      <br>
      <br>
      Rufnummer Servicedesk Hamburg:<br>
      <a href="tel:+494041565555">040 - 4156 5555</a>
    </span>
    <div style="margin-top: 3em;">
      <div class="font-weight-bolder" style="color: #565656;">Information gem. Art. 13 Datenschutzgrundverordnung</div>
      <div style="margin-top: 1em; margin-bottom: 1em;">der NDR informiert Sie hiermit – unabhängig von der Nutzung unserer Onlineangebote – über den Umgang mit Daten im NDR: Im Zeitpunkt der Datenerhebung, beispielsweise im Rahmen einer Vertragsbeziehung zur Erfüllung gegenseitig geschuldeter Leistungen und Pflichten oder in Wahrnehmung des Hausrechts und ggf. damit einhergehenden berechtigten Sicherheitsinteressen speichert der NDR personenbezogene Daten von Ihnen. Im Bezug auf das GVR-Tool sind es:</div>
      <div style=" margin-bottom: 1em;" class="font-italic">Name, E-Mail, Landesrundfunkanstalt, Rufnummer und Angaben zur Abteilung.</div>
      <div style=" margin-bottom: 1em;">Die komplette Datenschutzerklärung des NDR entnehmen sie bitte dem Link.</div>
      <div><a href="https://www.ndr.de/service/datenschutz/index.html" target="_blank">https://www.ndr.de/service/datenschutz/index.html</a></div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="this.dialogRef.close()">OK</button>
</div>
