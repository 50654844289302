<button *ngIf="!roletobeedited"
        matTooltip="Neue Rolle anlegen" [disabled]="!permissionchecker.userHasRight('create-users')"
        class="add-role-button" mat-raised-button color="primary" aria-label="Rolle erzeugen" (click)="openDialog()">
  + Neue Rolle
</button>

<button
  *ngIf="roletobeedited && (permissionchecker.userHasRight('create-users') || permissionchecker.userHasRight('update-users') || permissionchecker.userHasRight('delete-roles'))"
  matTooltip="Rolle bearbeiten" class="roles-edit-button" mat-icon-button [matMenuTriggerFor]="roleseditmenu">
  <mat-icon>edit</mat-icon>
</button>

<mat-menu #roleseditmenu="matMenu">
  <button *ngIf="permissionchecker.userHasRight('update-users')" mat-menu-item (click)="openDialog('edit')">Bearbeiten
  </button>
  <button *ngIf="permissionchecker.userHasRight('delete-users')" mat-menu-item (click)="deleteRole(roletobeedited.id)">
    Löschen
  </button>
</mat-menu>
