import {MatSortHeaderIntl} from '@angular/material/sort';
import {translateAttributeToLabel} from '../_helpers/translator';

const sortHeaderLabel = (id: string): string => {
  return 'Ändere Sortierrichtung für ' + translateAttributeToLabel(id);
};

export function getGermanSortHeaderIntl() {
  return new MatSortHeaderIntl();
}
