<nav class="navbar" role="navigation" aria-label="Navigation">

  <div class="container">

    <a class="titleContainer" routerLink="/">
      <img class="logo gvr-logo" src="/assets/img/gvr-logo-negative.svg" alt="GVR-Logo">
    </a>

    <div class="navigationWrapper">
      <a class="navigation-item no-sub-menu" routerLink="/" [ngClass]="{'current': this.router.url === '/'}"
         *ngIf="permissionchecker.userHasRight('read-productions')">Produktionen</a>

      <a class="navigation-item no-sub-menu" routerLink="/publications" [ngClass]="{'current': this.router.url === '/publications'}"
         *ngIf="permissionchecker.userHasRight('read-productions')">Publikationen</a>

      <a class="navigation-item" href="#" (click)="$event.preventDefault()" [matMenuTriggerFor]="servicemenu"
         [ngClass]="{'current': this.router.url === '/account'}">Service
        <mat-icon>arrow_drop_down</mat-icon>
      </a>

      <a class="navigation-item" href="#" (click)="$event.preventDefault()" [matMenuTriggerFor]="adminmenu"
         [ngClass]="{'current': ['/rules', '/users', '/rulesets'].includes(this.router.url)}"
         *ngIf="permissionchecker.userHasSomeRight(['update-rulesets', 'update-users', 'update-roles'])">Administration
        <mat-icon>arrow_drop_down</mat-icon>
      </a>

      <a class="navigation-item" href="#" (click)="$event.preventDefault()" [matMenuTriggerFor]="accountmenu"
         [ngClass]="{'current': this.router.url === '/account'}">Mein Konto
        <mat-icon>arrow_drop_down</mat-icon>
      </a>

      <mat-menu #servicemenu="matMenu">
        <a class="text-decoration-none" target="_blank" [href]="environment.serviceMenu.excelList">
          <button mat-menu-item>
            <mat-icon>import_export</mat-icon>
            Vorlage Datenmeldung
          </button>
        </a>
        <a class="text-decoration-none" target="_blank" [href]="environment.serviceMenu.help">
          <button mat-menu-item>
            <mat-icon>help_outline</mat-icon>
            Anwendungsanleitung
          </button>
        </a>
      </mat-menu>

      <mat-menu #adminmenu="matMenu">
        <button mat-menu-item routerLink="/rules" *ngIf="permissionchecker.userHasRight('update-rulesets')">
          <mat-icon>menu_book</mat-icon>
          Regelwerke
        </button>
        <button mat-menu-item routerLink="/users" *ngIf="permissionchecker.userHasRight('update-users')">
          <mat-icon>supervised_user_circle</mat-icon>
          Userverwaltung
        </button>
        <button mat-menu-item routerLink="/roles" *ngIf="permissionchecker.userHasRight('update-roles')">
          <mat-icon>lock</mat-icon>
          Rechte & Rollen
        </button>
      </mat-menu>

      <mat-menu #accountmenu="matMenu">
        <button mat-menu-item routerLink="/account">
          <mat-icon>account_circle</mat-icon>
          Profil
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          Logout
        </button>
      </mat-menu>

    </div>

  </div>

</nav>
