// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHostProtocol: '//',
  apiHost: 'ewerk:3w3rk!!@gvr.ewerk.de', // needed for local development
  apiPort: '',
  apiPath: '/api/v1',
  serviceMenu: {
    excelList: 'https://share.ard-zdf-box.de/apps/files/?dir=/Datenmeldung/Vorlage_Datenmeldung&fileid=66983094',
    help: 'https://share.ard-zdf-box.de/s/PzaQpLbyfksLZe9',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
