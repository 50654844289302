<div class="container-fluid container-fluid-padding"  role="main" aria-label="Übersicht der Produktionen">
  <div class="container position-relative">

    <app-publicationlist-search></app-publicationlist-search>

    <div class="publications-data">
      <table mat-table [dataSource]="dataSource" matSort [class.blurredout]="(dataSource.loading$ | async) || loading === true" aria-label="Tabelle mit Produktionen">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Nummer</th>
          <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt am</th>
          <td mat-cell *matCellDef="let row">
            {{row.created_at | date:'dd.MM.yyyy'}}
            <span>{{row.created_at | date:'HH:mm'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert am</th>
          <td mat-cell *matCellDef="let row">
            {{row.updated_at | date:'dd.MM.yyyy'}}
            <span>{{row.updated_at | date:'HH:mm'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ausstrahlung</th>
          <td mat-cell *matCellDef="let row">
            {{row.start | date:'dd.MM.yyyy'}} <span class="time">[{{row.start | date:'HH:mm'}}]</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="duration_minutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Länge</th>
          <td mat-cell *matCellDef="let row"> {{row.duration_minutes}}  min. </td>
        </ng-container>

        <ng-container matColumnDef="genre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Genre</th>
          <td mat-cell *matCellDef="let row"> {{row.genre}}</td>
        </ng-container>

        <ng-container matColumnDef="broadcaster_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>nutzende Anstalt</th>
          <td mat-cell *matCellDef="let row"> {{row.broadcaster_name}} </td>
        </ng-container>

        <ng-container matColumnDef="leading_broadcaster_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Federführung</th>
          <td mat-cell *matCellDef="let row"> {{row.leading_broadcaster_name}} </td>
        </ng-container>

        <ng-container matColumnDef="playout_title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ausspieltitel</th>
          <td mat-cell *matCellDef="let row">
            {{ row.playout_title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ruleset_applied">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Regelset</th>
          <td mat-cell *matCellDef="let row">
            <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
              {{singleTransaction.value.ruleset_applied}}:
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="transaction_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Verbrauch</th>
          <td mat-cell *matCellDef="let row">
            <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
              {{singleTransaction.value.amount ? singleTransaction.value.amount * -1 : 0}} Punkte
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="transaction_cost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Kosten (NV)</th>
          <td mat-cell *matCellDef="let row">
            <div class="d-block ruleset-border-bottom" *ngFor="let singleTransaction of row.transactions | keyvalue">
              {{singleTransaction.value.cost ? singleTransaction.value.cost + "€" : "0"}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered, 'is-under-zero': row.is_under_zero}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
        </tr>

      </table>

      <div class="table-loading-container" *ngIf="(dataSource.loading$ | async) || loading === true">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>

      <div class="table-empty-container" *ngIf="paginator.length === 0 && !(dataSource.loading$ | async)">
        <div class="empty-message">
          <strong>Keine Ergebnisse</strong>
          <span>Bitte überprüfen Sie Ihre Suche.</span>
        </div>

      </div>

      <div class="excel-export-container">
        <mat-icon matTooltip="Excel-Export" aria-hidden="false" aria-label="Excel-Export" (click)="exportPublications()">file_download</mat-icon>
      </div>

      <mat-paginator aria-label="Seitenauswahl" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
    <app-support-button></app-support-button>
    <app-help-button></app-help-button>

  </div>
</div>
