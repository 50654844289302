import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {StandardComponent} from './_layouts/standard/standard.component';
import {LoginComponent} from './login/login.component';
import {ProductionlistComponent} from './productionlist/productionlist.component';
import {RulesComponent} from './rules/rules.component';
import {ProductionComponent} from './production/production.component';
import {PublicationlistComponent} from './publicationlist/publicationlist.component';
import {UsermanagementComponent} from './usermanagement/usermanagement.component';

import { AuthGuard } from './_guards';
import { AccountComponent } from './account/account.component';
import {RolesComponent} from './roles/roles.component';

const routes: Routes = [
  // Login-Layout
  {
    path: 'login',
    component: LoginComponent,
    data: {} // No restriction
  },

  // Standard-Layout
  {
    path: '',
    component: StandardComponent,
    children: [
      {
        path: '',
        component: ProductionlistComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'read-productions'}
      },
      {
        path: 'publications',
        component: PublicationlistComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'read-productions'}
      },
      {
        path: 'production/:id',
        component: ProductionComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'read-productions'}
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        data: {} // No restriction if logged in
      },
      {
        path: 'users',
        component: UsermanagementComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'update-users'}
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'update-roles'}
      },
      {
        path: 'rules',
        component: RulesComponent,
        canActivate: [AuthGuard],
        data: {authorized: 'update-rulesets'}
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
