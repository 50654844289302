import { FormControl } from '@angular/forms';

export class RulesetValidator {
  static validRulesetCombination(fc: FormControl){
    const fieldValues = fc.value;
    const fieldValuesGvr = [];
    const rulesets = JSON.parse(localStorage.getItem('rulesets'));
    const validCombinations = [
      ['ARD-POC GVR 1001', 'ARD-POC GVR 1201'],
      ['ARD-POC GVR 1002', 'ARD-POC GVR 1200']
    ];

    if (fieldValues) {
      for (const fieldValue of fieldValues) {
        fieldValuesGvr.push(getGvrIdByRulesetID(fieldValue));
      }
    }

    if (fieldValues) {
      const combinationValidities = [];
      const checker = (arr, target) => target.every(v => arr.includes(v));

      for (const validCombination of validCombinations) {
        combinationValidities.push(checker(validCombination, fieldValuesGvr));
      }

      if (combinationValidities.includes(true) || fieldValues.length === 1) {
        return null;
      } else {
        return {validRulesetCombination: true};
      }
    }

    function getGvrIdByRulesetID(rulesetid) {
      return rulesets.find(x => x.id === rulesetid).gvr_id;
    }
  }
}
