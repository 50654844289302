<button *ngIf="!rulesettobeedited && permissionchecker.userHasRight('create-rulesets')"
        matTooltip="Neue Regel hinzufügen" class="rules-edit-button" mat-fab color="primary" aria-label="Regelwerk erzeugen" (click)="openDialog()">
  <mat-icon>add</mat-icon>
</button>

<button *ngIf="rulesettobeedited && (permissionchecker.userHasRight('create-rulesets') || permissionchecker.userHasRight('update-rulesets') || permissionchecker.userHasRight('delete-rulesets'))"
        matTooltip="Regel bearbeiten" class="rules-edit-button" mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="ruleseditmenu">
  <mat-icon>edit</mat-icon>
</button>

<mat-menu #ruleseditmenu="matMenu">
  <button *ngIf="permissionchecker.userHasRight('update-rulesets')" mat-menu-item (click)="openDialog('edit')">Bearbeiten</button>
  <button *ngIf="permissionchecker.userHasRight('create-rulesets')" mat-menu-item (click)="openDialog('duplicate')">Duplizieren</button>
  <button *ngIf="permissionchecker.userHasRight('delete-rulesets')" mat-menu-item (click)="deleteRuleset(rulesettobeedited.id)">Löschen</button>
</mat-menu>


