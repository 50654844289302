<div class="container-fluid login-container" role="main">
  <div class="login-container-inner">

    <div class="login-logo-container">
      <img class="login-logo" src="/assets/img/gvr-logo-positive.svg">
      <div *ngIf="!environment.production">(Test)</div>
    </div>

    <div *ngIf="error" class="alert alert-danger">{{error}}</div>

      <div class="login-wrapper" *ngIf="showLoginForm == true;">
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <input matInput placeholder="Benutzername" name="username" required formControlName="username"
                   [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Passwort</mat-label>
            <input type="password" matInput formControlName="password" placeholder="Passwort" name="password" required
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }">

            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </mat-form-field>

          <div class="reset-password-wrapper">
            <button type="button" (click)="showResetPasswordView();" mat-button>
              Passwort zurücksetzen
              <span class="divider"> | </span>
              Konto aktivieren
            </button>
          </div>

          <div class="button-wrapper">
            <button [disabled]="loading" mat-raised-button color="primary">
              <mat-spinner color="primary" diameter="15" [hidden]="!loading"></mat-spinner>
              <span class="button-label">Login</span>
            </button>
          </div>

        </form>
      </div>

      <div *ngIf="showResetPassword">

        <span class="form-message">
          Um ihr Konto zu aktivieren oder ihr Passwort zurückzusetzen geben Sie bitte ihre E-Mail ein.
        </span>

        <form class="login-form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassWord()">

          <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <input matInput type="email" placeholder="E-Mail" name="email" required formControlName="email">
          </mat-form-field>
          <div class="button-wrapper">
            <button type="button" class="mr-2" mat-button (click)="showLoginFormView();">zurück</button>
            <button [disabled]="loading" mat-raised-button color="primary">
              <mat-spinner color="primary" diameter="15" [hidden]="!loading"></mat-spinner>
              Passwort zusenden
            </button>
          </div>

        </form>
      </div>
    <app-support-button></app-support-button>
  </div>
</div>
