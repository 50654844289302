<div class="container-fluid container-fluid-padding" role="main">
  <div class="container">
    <button *ngIf="permissionchecker.userHasRight('read-productions')" mat-icon-button color="primary" aria-label="zurück zur Übersicht" routerLink="/">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="search-field-wrapper usermanagement-search">
      <form aria-label="Produktionssuche" [formGroup]="searchForm">
        <mat-form-field>
          <input matInput aria-label="Produktionssuche" placeholder="Nutzer Volltextsuche" formControlName="inputValue">
        </mat-form-field>
        <button aria-label="Suche ausführen" class="search-button" mat-mini-fab color="primary" (click)="search()"><mat-icon>search</mat-icon></button>
      </form>
    </div>

    <div class="usermanagement-data" [hidden]="loading === true">
      <div class="headline-container">
        <h1 class="headline">
          <span class="headline-description">Administration:</span>
          <mat-icon>supervised_user_circle</mat-icon>
          Userverwaltung

          <app-user-edit-button (refreshrequest)="refreshUsers();" (showloader)="showLoader();"></app-user-edit-button>
        </h1>
      </div>


      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="firstname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
          <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </th>
          <td mat-cell *matCellDef="let row"> {{row.lastname}} </td>
        </ng-container>

        <ng-container matColumnDef="broadcaster_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Anstalt </th>
          <td mat-cell *matCellDef="let row"> {{broadcasterNames ? broadcasterNames[row.broadcaster_id] : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rollen </th>
          <td mat-cell *matCellDef="let row"> {{ rolesToString(row.roles) }}</td>
        </ng-container>

        <ng-container matColumnDef="show_details">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <app-user-edit-button [usertobeedited]="row" (refreshrequest)="refreshUsers();" (showloader)="showLoader();"></app-user-edit-button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
        </tr>
      </table>

      <div class="table-loading-container" *ngIf="loading === true">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>




    </div>

    <div class="usermanagement-data usermanagement-data-empty-container" [hidden]="loading === false">
      <div class="spinner-center">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>
    </div>

  </div>
</div>
