import {FormControl} from '@angular/forms';
export class Confirmpassword {
  static MatchPassword(abstractControl: FormControl) {
    return new Promise( resolve => {
      const password = abstractControl.parent.controls[`newpassword`].value; // to get value in input tag
      const confirmPassword = abstractControl.value; // to get value in input tag
      if (password === confirmPassword) {
        return resolve(null); // All ok, passwords match!!!
      } else {
        return resolve({not_match: true});
      }
    });
  }
}
