import { Component, OnInit } from '@angular/core';
import {ApiService} from '../_services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Broadcaster} from '../broadcaster';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit {
  currentuser = JSON.parse(localStorage.getItem('currentUser'));
  loaded = true;
  accountform: FormGroup;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  isSaving = false;
  updateduserdata;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public permissionchecker: PermissioncheckerService,
    private titleService: Title,
  ) { }

  updateAccountData() {
    if (this.accountform.invalid) {
      return;
    } else {
      this.isSaving = true;
    }

    this.apiService.updateAccount(this.accountform.value).then(
      data => {
        this.updateduserdata = data;
        this.isSaving = false;
        this.snackBar.open('Ihre Daten wurden erfolgreich aktualisiert.', 'OK', {
          duration: 8000,
        });
      },
    ).catch((error: any) => {
      this.isSaving = false;

      if (error) {
        this.snackBar.open('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      }
    });

  }

  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed === true) {
        this.snackBar.open('Passwort erfolgreich geändert!', 'OK', {
          duration: 4000,
        });
      } else {
        this.snackBar.open('Ihr Passwort wurde nicht geändert.', 'OK', {
          duration: 4000,
        });
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle("GVR-Tool | Benutzerkonto");
    this.accountform = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', Validators.required],
      broadcaster: ['', Validators.required],
      position: ['', Validators.required],
    });

    this.accountform.patchValue({
      firstname: this.currentuser.firstname,
      lastname: this.currentuser.lastname,
      phone: this.currentuser.phone,
      broadcaster: this.currentuser.broadcaster_id,
      position: this.currentuser.department,
    });
  }
}
