<div class="container-fluid container-fluid-padding" role="main">
  <div class="container">

    <button *ngIf="permissionchecker.userHasRight('read-productions')" mat-icon-button color="primary" aria-label="zurück zur Übersicht" routerLink="/">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="roles-data" [hidden]="loading === true">
      <div class="headline-container">
        <h1 class="headline">
          <span class="headline-description">Administration:</span>
          <mat-icon>lock</mat-icon>
          Rechte & Rollen

          <app-roles-edit-button (refreshrequest)="refreshRoles();" (showloader)="showLoader();"></app-roles-edit-button>
        </h1>
      </div>


      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>urce]="dataSource" matSort class="w-100">

        <ng-container matColumnDef="displayname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.display_name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Beschreibung </th>
          <td mat-cell *matCellDef="let row"> {{row.description}} </td>
        </ng-container>

        <ng-container matColumnDef="show_details">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <app-roles-edit-button [roletobeedited]="row" (refreshrequest)="refreshRoles();" (showloader)="showLoader();"></app-roles-edit-button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
        </tr>
      </table>

      <div class="table-loading-container" *ngIf="loading === true">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>

    <div class="roles-data roles-data-empty-container" [hidden]="loading === false">
      <div class="spinner-center">
        <mat-spinner color="primary" diameter="75"></mat-spinner>
      </div>
    </div>

  </div>
</div>
