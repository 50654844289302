<div class="search-field-wrapper">
  <form [formGroup]="productionList.simpleSearchForm" (ngSubmit)="productionList.searchProductions()" aria-label="Produktionssuche">
    <mat-form-field [class.blurredout]="productionList.showexpertsearch === true" [attr.aria-hidden]="productionList.showexpertsearch === true">
      <input matInput formControlName="simpleSearchInput" aria-label="Produktionssuche" placeholder="Titel der Produktion">
    </mat-form-field>
    <button aria-label="Suche ausführen" class="search-button" [attr.aria-hidden]="productionList.showexpertsearch === true" [class.blurredout]="productionList.showexpertsearch === true" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
  </form>

  <div class="expand-expertsearch-wrapper">
    <button [attr.aria-label]="productionList.showexpertsearch ? 'Erweiterte Suche schließen' : 'Erweiterte Suche anzeigen'" class="expert-search-button" type="button" mat-stroked-button color="primary" (click)="productionList.toggleExpertSearch()">
      {{productionList.showexpertsearch ? 'Erweiterte Suche schließen' : 'Erweiterte Suche anzeigen'}}
    </button>

    <button class="expert-search-button" *ngIf="productionList.showexpertsearch" type="button" mat-stroked-button color="primary" title="Expertensuche zurücksetzten" (click)="productionList.resetExpertSearch(true)">
      zurücksetzen
    </button>
  </div>
</div>

<mat-accordion class="expertsearch-collapse">
  <mat-expansion-panel [expanded]="productionList.showexpertsearch">
    <div class="expertsearch-wrapper">

      <form [formGroup]="productionList.expertSearchForm" (ngSubmit)="productionList.detailSearchProductions()">
        <mat-form-field>
          <input matInput placeholder="Titel" name="title" formControlName="title">
        </mat-form-field>

        <mat-form-field class="broadcaster">
          <mat-label>Federführung</mat-label>
          <mat-select [attr.role]="'selectbox'" name="broadcaster_id" name="broadcaster" formControlName="broadcaster" multiple>
            <mat-option *ngFor="let broadcaster of productionList.broadcasters" [value]="broadcaster.id" [hidden]="broadcaster.is_streaming_broadcaster === 1">{{broadcaster.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="genre">
          <mat-label>Genre</mat-label>
          <mat-select [attr.role]="'selectbox'" name="genre" name="genre" formControlName="genre" multiple>
            <mat-option *ngFor="let singleGenre of productionList.genres" [value]="singleGenre">{{singleGenre}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="year">
          <input matInput placeholder="Erstsendung" name="year" formControlName="year">
        </mat-form-field>

        <mat-form-field class="score_min">
          <input name="score_min" formControlName="score_min" matInput placeholder="min-Punkte">
        </mat-form-field>

        <span class="points-divider">-</span>

        <mat-form-field class="score_max">
          <input name="score_max" formControlName="score_max" matInput placeholder="max-Punkte">
        </mat-form-field>

        <mat-form-field class="rules">
          <mat-label>GVR-Regeln</mat-label>
          <mat-select [attr.role]="'selectbox'" name="rules" formControlName="rules" multiple>
            <mat-option *ngFor="let rule of productionList.rules" [value]="rule.id">{{rule.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button class="search-button" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
      </form>


    </div>
  </mat-expansion-panel>
</mat-accordion>
