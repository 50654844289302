import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../_services/api.service';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProductionsDataSource} from '../_datasources/ProductionsDataSource';
import {tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {merge} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {Ruleset} from '../ruleset';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-productionlist',
  templateUrl: './productionlist.component.html'
})
export class ProductionlistComponent implements OnInit {

  displayedColumns: string[] = ['id', 'title', 'brid', 'genre', 'year', 'lra', 'current_score', 'ruleset', 'show_details'];
  dataSource = new ProductionsDataSource(this.apiService);
  selection = new SelectionModel(false, []);
  expertSearchForm: FormGroup;
  simpleSearchForm: FormGroup;
  rules: Ruleset[] = [];
  genres;
  loading = false;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  broadcasterNames: object = JSON.parse(localStorage.getItem('broadcasternames'));
  showexpertsearch = false;
  search = {detailSearch: false, data: {title: '', broadcaster: '', genre: '', year: '', score_min: '', score_max: '', rules: [], simpleSearchInput: ''}};

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private apiService: ApiService,
    public permissionchecker: PermissioncheckerService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.titleService.setTitle('GVR-Tool | Produktionsliste');

    if (this.permissionchecker.userHasRight('read-rulesets')) {
      this.apiService.getRulesets().subscribe((rules) => {
        this.rules = rules;
      });
    }

    this.apiService.getGenres().subscribe((genres) => {
      this.genres = genres;
    });

    this.initSearch();
    this.setSearch();
    this.updateProductionList();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.updateProductionList())
      )
      .subscribe();
  }

  routingToProduction(id) {
    this.router.navigate(['/production/' + id]);
  }

  setSearch() {
    const savedSearch = JSON.parse(localStorage.getItem('search'));

    if (savedSearch === null) {
      return false;
    }

    this.search = savedSearch;

    if (this.search.detailSearch) {
      this.expertSearchForm.patchValue({
        title: this.search.data.title,
        broadcaster: this.search.data.broadcaster,
        genre: this.search.data.genre,
        year: this.search.data.year,
        score_min: this.search.data.score_min,
        score_max: this.search.data.score_max,
        rules: (this.permissionchecker.userHasRight('read-rulesets') ? this.search.data.rules : [] ),
      });

      this.showexpertsearch = true;
    } else {
      this.simpleSearchForm.patchValue({
        simpleSearchInput: this.search.data.simpleSearchInput !== undefined ? this.search.data.simpleSearchInput : this.search.data,
      });
    }
  }

  initSearch() {
    this.expertSearchForm = this.formBuilder.group({
      title: [null],
      broadcaster: [null],
      genre: [null],
      year: [null],
      score_min: [null],
      score_max: [null],
      rules: [{value: null, disabled: !this.permissionchecker.userHasRight('read-rulesets')}],
    });

    this.simpleSearchForm = this.formBuilder.group({
      simpleSearchInput: ['']
    });
  }

  updateProductionList() {
    localStorage.setItem('search', JSON.stringify(this.search));
    this.dataSource.loadProductions({
      search: this.search,
      pageNumber: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize,
      sortOrder: this.sort.direction,
      sortField: this.sort.active
    });
    this.dataSource.productionsPayload.asObservable().subscribe(data => {
      // @ts-ignore
      this.paginator.length = data.total;
    });
  }

  toggleExpertSearch() {
    this.showexpertsearch = !this.showexpertsearch;

    if (this.showexpertsearch === false) {
      this.resetExpertSearch();
      this.searchProductions();
    }
  }

  resetExpertSearch(doSearch = false) {
    this.expertSearchForm = this.formBuilder.group({
      title: [null],
      broadcaster: [null],
      genre: [null],
      year: [null],
      score_min: [null],
      score_max: [null],
      rules: [{value: null, disabled: !this.permissionchecker.userHasRight('read-rulesets')}],
    });

    if (doSearch === true) {
      this.detailSearchProductions();
    }
  }

  searchProductions() {
    this.selection.clear();
    if (this.simpleSearchForm.invalid) {
      return;
    }
    this.paginator.pageIndex = 0;
    this.search.data = this.simpleSearchForm.value.simpleSearchInput;
    this.search.detailSearch = false;
    this.updateProductionList();
  }

  loadProductions() {
    this.updateProductionList();
  }

  detailSearchProductions() {
    this.selection.clear();
    if (this.expertSearchForm.invalid) {
      return;
    }
    this.paginator.pageIndex = 0;
    this.search.data = this.expertSearchForm.value;
    this.search.detailSearch = true;
    this.updateProductionList();
  }

  findRulesetByID(idToFind) {
    return this.rules.find(x => x.id === idToFind);
  }

  exportProductions() {
    let productionsExport;
    const searchConfig = {
      search: this.search,
      pageNumber: 0,
      pageSize: 99999,
      sortOrder: this.sort.direction,
      sortField: this.sort.active
    };
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    const columnNames = {
      id: 'id',
      created_at: 'Angelegt',
      updated_at: 'Aktualisiert',
      title: 'Titel',
      genre: 'Genre',
      year: 'Erstsendung',
      created_by_user_id: 'Angelegt von User',
      updated_by_user_id: 'Aktualisiert von User',
      type: 'Typ',
      destination: 'Zielland',
      deleted_at: 'Gelöscht am',
      poc_id: 'POC-ID',
      brid: 'creationBRID',
      initial_gvr_creation: 'GVR-Eingang',
      leading_broadcaster_id: 'Federführung',
      duration_gross: 'Länge',
      sub_title: 'Nebentitel',
      roof_title: 'Dach-Titel',
      topic_title: 'Thema',
      original_title: 'Originaltitel',
      editorial_broadcaster_id: 'Redaktionelle Führung',
      broadcasted_by_streaming: 'Ausschließlich Streaming?',
      scores: 'Guthaben',
      ruleset_ids: 'Regelwerke',
    };
    const columnsToExport = [
      'title',
      'brid',
      'genre',
      'year',
      'leading_broadcaster_id',
      'scores',
      'ruleset_ids',
    ];

    this.loading = true;

    if (this.showexpertsearch === true) {

      if (this.expertSearchForm.invalid) {
        this.loading = false;
        return;
      }
      this.search.data = this.expertSearchForm.value;
      this.search.detailSearch = true;

      this.apiService.detailSearchProductions(searchConfig).then(productions => {
        productionsExport = productions.data;

        for (const singleProduction of productionsExport) {
          singleProduction.leading_broadcaster_id = this.broadcasterNames[singleProduction.leading_broadcaster_id];
          let singleProductionScores = '';
          let singleProductionRulesets = '';

          for (const singleProductionScore of singleProduction.scores) {
            singleProductionScores += singleProductionScore.current_score + ', ';
          }

          for (const singleProductionRulesetsId of singleProduction.ruleset_ids) {
            singleProductionRulesets += this.findRulesetByID(singleProductionRulesetsId).name + ', ';
          }

          singleProduction.scores = singleProductionScores.substring(0, singleProductionScores.length - 2);
          singleProduction.ruleset_ids = singleProductionRulesets.substring(0, singleProductionRulesets.length - 2);

          for (const singleProductionProperty of Object.keys(singleProduction)) {
            if (!columnsToExport.includes(singleProductionProperty)) {
              delete singleProduction[singleProductionProperty];
            }
          }
        }

        writeToExcel();
        this.loading = false;
      });

    } else {

      if (this.simpleSearchForm.invalid) {
        this.loading = false;
        return;
      }
      this.search.data = this.simpleSearchForm.value.simpleSearchInput;
      this.search.detailSearch = false;

      this.apiService.searchProductions(searchConfig).then(productions => {
        productionsExport = productions.data;

        for (const singleProduction of productionsExport) {
          singleProduction.leading_broadcaster_id = this.broadcasterNames[singleProduction.leading_broadcaster_id];
          let singleProductionScores = '';
          let singleProductionRulesets = '';

          for (const singleProductionScore of singleProduction.scores) {
            singleProductionScores += singleProductionScore.current_score + ', ';
          }

          for (const singleProductionRulesetsId of singleProduction.ruleset_ids) {
            singleProductionRulesets += this.findRulesetByID(singleProductionRulesetsId).name + ', ';
          }

          singleProduction.scores = singleProductionScores.substring(0, singleProductionScores.length - 2);
          singleProduction.ruleset_ids = singleProductionRulesets.substring(0, singleProductionRulesets.length - 2);

          for (const singleProductionProperty of Object.keys(singleProduction)) {
            if (!columnsToExport.includes(singleProductionProperty)) {
              delete singleProduction[singleProductionProperty];
            }
          }
        }

        writeToExcel();
        this.loading = false;
      });
    }

    function writeToExcel() {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionsExport);
      const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + '1';
        if (!worksheet[address]) {
          continue;
        }
        worksheet[address].v = columnNames[worksheet[address].v];
      }
      XLSX.writeFile(workbook, 'NDR-GVR-Tool-Export_Produktionen_' + currentDate + '_' + currentTime + '.xlsx');
    }
  }
}
