import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../_services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {User} from '../_models';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html'
})
export class UsermanagementComponent implements OnInit {
  displayedColumns: string[] = ['id', 'firstname', 'lastname', 'broadcaster_id', 'email', 'roles', 'show_details'];
  dataSource = new MatTableDataSource();
  users: User[];
  loading = false;
  broadcasterNames: object = JSON.parse(localStorage.getItem('broadcasternames'));
  public searchForm: FormGroup = new FormGroup({
    inputValue: new FormControl('')
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    private apiService: ApiService,
    public permissionchecker: PermissioncheckerService,
    private titleService: Title,
  ) {}

  ngOnInit() {

    this.titleService.setTitle('GVR-Tool | Nutzerverwaltung');

    this.loading = true;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.apiService.getUsers().subscribe((users) => {
      this.users = users;
      this.dataSource.data = users;
      this.loading = false;
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (data, sort) => {
      const sortField = this.sort.sortables.get(sort);

      if (sortField !== undefined) {
        // @ts-ignore
        return data[sortField._columnDef._name];
      }

      return '';
    };

    this.dataSource.filterPredicate = (data, searchstring) => {
      let searchable = '';

      Object.keys(data).forEach(key => {
        if (typeof data[key] === 'string') {
          searchable += data[key] + ' ';
        } else if ((data[key] instanceof Array) && key === 'roles') {
          searchable += data[key].map(role => role.display_name + ' ') + ' ';
        } else if (key === 'broadcaster_id') {
          searchable += this.broadcasterNames[data[key]] + ' ';
        }
      });
      return searchable.toLowerCase().indexOf(searchstring) > -1;
    };
  }

  search() {
    this.dataSource.filter = this.searchForm.value.inputValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  refreshUsers(): void {
    this.apiService.getUsers().subscribe((users: User[]) => {
      this.users = users;
      this.dataSource.data = users;
      this.loading = false;
    });
  }

  showLoader(): void {
    this.loading = true;
  }

  rolesToString(roles): string {
    const rolesArray = roles.map(role => {
      return role.name;
    });

    return rolesArray.join(', ');
  }
}
