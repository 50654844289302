import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

import {User} from '../_models';
import {ApiService} from './api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  apiURL = environment.apiHostProtocol + (environment.apiHost.length > 0 ? environment.apiHost : window.location.hostname) + environment.apiPort + environment.apiPath;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${this.apiURL}/post/login`, {username, password}).pipe(map(user => {
      if (user && user.access_token) {

        this.apiService.getCurrentUser(user.access_token).subscribe((userdata) => {
          const allUserData = Object.assign(user, userdata);
          localStorage.setItem('currentUser', JSON.stringify(allUserData));

          if (allUserData.allPermissions.filter(p => p.name === 'read-broadcaster').length > 0) {
            this.apiService.getBroadcasters(user.access_token).subscribe((broadcasters) => {
              // tslint:disable-next-line:only-arrow-functions
              broadcasters.sort(function(a, b){
                if (a.broadcaster_name.toLowerCase( ) < b.broadcaster_name.toLowerCase( )) { return -1; }
                if (a.broadcaster_name.toLowerCase( ) > b.broadcaster_name.toLowerCase( )) { return 1; }
                return 0;
              });


              localStorage.setItem('broadcasters', JSON.stringify(broadcasters));
              const broadcasterNames = [];
              for (const broadcaster of broadcasters) {
                broadcasterNames[broadcaster.id] = broadcaster.name;
              }
              localStorage.setItem('broadcasternames', JSON.stringify(broadcasterNames));
            });

            this.apiService.getStreamingBroadcasters(user.access_token).subscribe((streamingbroadcasters) => {
              localStorage.setItem('streamingbroadcasters', JSON.stringify(streamingbroadcasters));
            });
          }

          if (allUserData.allPermissions.filter(p => p.name === 'read-productions').length > 0) {
            this.currentUserSubject.next(user);
            this.router.navigate([this.route.snapshot.queryParams.returnUrl || '/']);
            return user;
          } else {
            this.snackBar.open('Sie haben keine ausreichenden Rechte. Login abgebrochen.', 'OK', {
              duration: 4000,
            });
            this.logout();
          }
        });
      }
    }));
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public arrayContainsValue(multidimensionalarray, value) {
    let index = 0;

    for (let i = 1; i < multidimensionalarray.length; i++) {
      if (multidimensionalarray[i].name === value) {
        index = i;
        break;
      }
    }

    return index > 0;
  }
}
