import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Production} from '../production';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from '../_services/api.service';

export class PublicationsDataSource implements DataSource<Production> {

  private publicationsSubject = new BehaviorSubject<Production[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public sortingDataAccessor;

  public publicationsPayload = new BehaviorSubject<Production[]>([]);

  constructor(private apiService: ApiService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Production[] | ReadonlyArray<Production>> {
    return this.publicationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.publicationsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPublications(data) {
    this.loadingSubject.next(true);

    if (data.search.detailSearch) {
      this.apiService.detailSearchPublications(data).then(publications => {
        this.loadingSubject.next(false);
        this.publicationsSubject.next(publications.data);
        this.publicationsPayload.next(publications);
      });
    } else {
      this.apiService.searchPublications(data).then(publications => {
        this.loadingSubject.next(false);
        this.publicationsSubject.next(publications.data);
        this.publicationsPayload.next(publications);
      });
    }
  }
}
