<div class="user-data-form">
  <h1 mat-dialog-title *ngIf="data.mode === 'create'">Neuen User anlegen</h1>
  <h1 mat-dialog-title *ngIf="data.mode === 'edit'">User bearbeiten ({{data.user.name }})</h1>

  <form [formGroup]="userseditform" (ngSubmit)="postUser(data.mode)">

    <div mat-dialog-content>

      <mat-form-field>
        <input name="firstname" [formControl]="firstname" type="text" matInput placeholder="Vorname">
        <mat-error *ngIf="firstname.hasError('required')">Der Vorname ist zwingend erforderlich</mat-error>
        <mat-error *ngIf="firstname.hasError('minlength')">Der Vorname darf nicht unter 3 Zeichen lang sein</mat-error>
        <mat-error *ngIf="firstname.hasError('maxlength')">Der Vorname darf nicht über 255 Zeichen lang sein</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input name="lastname" [formControl]="lastname" type="text" matInput placeholder="Nachname">
        <mat-error *ngIf="lastname.hasError('required')">Der Nachname ist zwingend erforderlich</mat-error>
        <mat-error *ngIf="lastname.hasError('minlength')">Der Nachname darf nicht unter 3 Zeichen lang sein</mat-error>
        <mat-error *ngIf="lastname.hasError('maxlength')">Der Nachname darf nicht über 255 Zeichen lang sein</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input name="email" [formControl]="email" type="email" matInput placeholder="E-Mail">
        <mat-error *ngIf="email.hasError('required')">Die Email ist zwingend erforderlich</mat-error>
        <mat-error *ngIf="email.hasError('email')">Keine valide Email</mat-error>
        <mat-error *ngIf="email.hasError('maxlength')">Die Email darf nicht über 255 Zeichen lang sein</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input [formControl]="phone" type="phone" matInput placeholder="Telefon">
        <mat-error *ngIf="phone.hasError('required')">Die Telefonnummer ist zwingend erforderlich</mat-error>
        <mat-error *ngIf="phone.hasError('minlength')">Die Telefonnummer darf nicht unter 3 Zeichen lang sein</mat-error>
        <mat-error *ngIf="phone.hasError('maxlength')">Die Telefonnummer darf nicht über 255 Zeichen lang sein</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Anstalt</mat-label>
        <mat-select [attr.role]="'selectbox'" name="broadcaster" [formControl]="broadcasterFormControl" (openedChange)="checkBroadcasterOrCompany('broadcaster')">
          <mat-option [value]="'none'">Keiner Anstalt zugehörig</mat-option>
          <mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.id" [hidden]="broadcaster.is_streaming_broadcaster === 1">{{broadcaster.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="broadcasterFormControl.hasError('required')">Die Anstalt ist zwingend erforderlich</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input formControlName="company" type="company" matInput placeholder="Firma" (input)="checkBroadcasterOrCompany('company')">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Rolle</mat-label>
        <mat-select [attr.role]="'selectbox'" name="roles" [formControl]="rolesFormControl" [value]="(data.mode === 'edit' ? data.user.allRoles : [])" multiple>
          <mat-option *ngFor="let role of roles" [value]="role.name">{{role.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="rolesFormControl.hasError('required')">Die Rolle ist zwingend erforderlich</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input name="position" [formControl]="department" type="text" matInput placeholder="Funktion / Abteilung">
        <mat-error *ngIf="department.hasError('required')">Die Funktion/Abteilung ist zwingend erforderlich</mat-error>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button mat-button color="primary" (click)="closeDialogSaving(false)">abbrechen</button>
      <button type="button" mat-button color="warn" *ngIf="data.mode !== 'create' && permissionchecker.userHasRight('delete-users')" (click)="deleteUser()">löschen</button>
      <button mat-raised-button color="primary" [disabled]="isSaving">
        <mat-spinner color="primary" diameter="15" [hidden]="isSaving === false"></mat-spinner>
        {{data.mode === 'create' ? 'User anlegen' : 'Speichern'}}
      </button>
    </div>
  </form>

  <div class="loading-spinner" *ngIf="loading === true">
    <mat-spinner color="primary" diameter="75"></mat-spinner>
  </div>
</div>
