import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../_services/api.service';
import {ActivatedRoute} from '@angular/router';
import {Production, ProductionPayload} from '../production';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Broadcast} from '../broadcast';
import {Ruleset} from '../ruleset';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Score} from '../score';
import {Title} from '@angular/platform-browser';
import {RulesetValidator} from '../_validators/ruleset.validator';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html'
})

export class ProductionComponent implements OnInit {
  public simulation: EventEmitter<boolean> = new EventEmitter();
  productionid: number = Number(this.route.snapshot.paramMap.get('id'));
  displayedColumns = ['id', 'playout_title', 'broadcaster_name', 'start', 'stop', 'duration_minutes', 'ruleset_applied', 'used_points', 'transaction_cost'];
  displayedColumnsStreaming = ['id', 'playout_title', 'broadcaster_name', 'start', 'duration_minutes', 'ruleset_applied', 'used_points', 'transaction_cost'];
  rulesets: Ruleset[];
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  streamingbroadcasters: object = JSON.parse(localStorage.getItem('streamingbroadcasters'));
  loaded: boolean;
  addBroadcastData: FormGroup;
  currentdate;
  currenttime;
  isSavingViews = false;
  isSaving = false;
  productionform;
  productionfirstbroadcast;

  pastpublicationsdata;
  futurepublicationsdata;
  streamingpublicationsdata;

  @ViewChild(MatSort, {static: true}) sortpastpublications: MatSort;
  @ViewChild(MatSort, {static: true}) sortfuturepublications: MatSort;
  @ViewChild(MatSort, {static: true}) sortstreamingpublications: MatSort;

  currentproduction = new ProductionPayload();
  pastpublications = new MatTableDataSource();
  futurepublications = new MatTableDataSource();
  streamingpublications = new MatTableDataSource();
  scores = [new Score()];

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    public permissionchecker: PermissioncheckerService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    this.currentdate = formatDate(new Date(), 'dd.MM.yyyy', 'en');
    this.currenttime = formatDate(new Date(), 'HH:mm', 'en');
  }

  ngOnInit(triggered = false) {
    this.titleService.setTitle('GVR-Tool | Produktions-Detailansicht');

    this.loaded = false;
    this.simulation = new EventEmitter();

    if (triggered === false && this.permissionchecker.userHasRight('delete-broadcast')) {
      this.displayedColumns.push('delete');
      this.displayedColumnsStreaming.push('delete');
    }

    this.addBroadcastData = this.formBuilder.group({
      production_id: [this.productionid],
      broadcaster_id: [0],
      startDate: [this.currentdate],
      startTime: [this.currenttime],
    });

    this.productionform = this.formBuilder.group({
      production_id: [this.productionid, Validators.required],
      leading_broadcaster_id: [{ value: 0, disabled: !this.permissionchecker.userHasRight('update-productions')}, Validators.required],
      editorial_broadcaster_id: [{ value: 0, disabled: !this.permissionchecker.userHasRight('update-productions')}, Validators.required],
      ruleset_ids: [] = [{ value: 0, disabled: !this.permissionchecker.userHasRight('update-productions') || !this.permissionchecker.userHasRight('read-rulesets')}, Validators.compose([Validators.required, RulesetValidator.validRulesetCombination])],
    });

    this.apiService.getProductionById(this.productionid).subscribe((production: ProductionPayload) => {
      this.currentproduction = production;
      this.scores = production.scores;

      this.productionform.patchValue({
        ruleset_ids: this.currentproduction.ruleset_ids,
        leading_broadcaster_id: this.currentproduction.leading_broadcaster_id,
        editorial_broadcaster_id: (this.currentproduction.editorial_broadcaster_id ? this.currentproduction.editorial_broadcaster_id : this.currentproduction.leading_broadcaster_id),
      });

      this.pastpublicationsdata = production.broadcasts;
      this.futurepublicationsdata = production.broadcasts;
      this.streamingpublicationsdata = production.broadcasts;

      this.updatePastProductions();
      this.updateFutureProductions();
      this.updateStreamingProductions();

      this.productionfirstbroadcast = formatDate(
        this.currentproduction.broadcasts[0].start,
        'dd.MM.yyyy',
        'de');

      this.loaded = true;
    });

    if (this.permissionchecker.userHasRight('read-rulesets')) {
      this.apiService.getRulesets().subscribe((ruleset: Ruleset[]) => {
        this.rulesets = ruleset;
        localStorage.setItem('rulesets', JSON.stringify(ruleset));
      });
    }

    this.addBroadcastData.patchValue({
      broadcaster_id: this.streamingbroadcasters[0].id,
    });

    if (this.simulation) {
      this.simulation.subscribe(data => {
        if (data === true) {
          this.ngOnInit(true);
        }
      });
    }
  }

  updateProduction(): void {
    if (this.productionform.invalid) {
      return;
    } else {
      this.isSaving = true;
    }

    this.apiService.updateProduction(this.productionform.value).then(
      data => {
        this.isSaving = false;
        this.ngOnInit(true);
        this.snackBar.open('Änderungen an der Produktion erfolgreich abgespeichert.', 'OK', {
          duration: 4000,
        });
      },
    ).catch((error: any) => {
      this.isSaving = false;
      this.snackBar.open('Es ist ein Fehler aufgetreten. Ihre Änderung wurde nicht gespeichert.', 'OK', {
        duration: 8000,
      });
      console.log(error);
    });
  }

  deleteBroadcast(productionid: number, datasource: string) {
    let targetsource;

    switch (datasource) {
      case 'past':
        targetsource = this.pastpublications;
        break;
      case 'future':
        targetsource = this.futurepublications;
        break;
      case 'streaming':
        targetsource = this.streamingpublications;
        break;
    }

    this.loaded = false;

    this.apiService.deleteBroadcast(productionid).then(data => {
      this.ngOnInit(true);
      this.loaded = true;
    });
  }

  add100kviews(): void {
    this.isSavingViews = true;

    this.apiService.createBroadcast(this.addBroadcastData.value).then(
      data => {
        this.isSavingViews = false;
        this.ngOnInit(true);
        this.snackBar.open('100.000 Views erfolgreich hinzugefügt!', 'OK', {
          duration: 4000,
        });
      }
    ).catch((error: any) => {
      this.snackBar.open('Es ist ein Fehler aufgetreten. Die Views wurden nicht gespeichert.', 'OK', {
        duration: 8000,
      });
      console.log(error);
      this.isSavingViews = false;
    });
  }

  updatePastProductions() {
    this.pastpublications.data = this.pastpublicationsdata;
    this.pastpublications.filterPredicate = (data: Broadcast) => {
      return new Date(data.start) <= new Date() && data.broadcaster_id !== this.streamingbroadcasters[0].id;
    };
    this.pastpublications.filter = 'updatePastProductions';
    this.pastpublications.sort = this.sortpastpublications;
  }

  updateFutureProductions() {
    this.futurepublications.data = this.futurepublicationsdata;
    this.futurepublications.filterPredicate = (data: Broadcast) => {
      return new Date(data.start) > new Date() && data.broadcaster_id !== this.streamingbroadcasters[0].id;
    };
    this.futurepublications.filter = 'updateFutureProductions';
    this.futurepublications.sort = this.sortfuturepublications;
  }

  updateStreamingProductions() {
    this.streamingpublications.data = this.streamingpublicationsdata;
    this.streamingpublications.filterPredicate = (data: Broadcast) => {
      return data.broadcaster_id === this.streamingbroadcasters[0].id;
    };
    this.streamingpublications.filter = 'updateStreamingProductions';
    this.streamingpublications.sort = this.sortstreamingpublications;
  }
}
