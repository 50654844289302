import {Component, Input, Output, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../_services/api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {EventEmitter} from '@angular/core';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-user-edit-button',
  templateUrl: './user-edit-button.component.html'
})
export class UserEditButtonComponent {
  dialogRefDataMode;

  @Input() usertobeedited = null;
  @Output() refreshrequest = new EventEmitter<string>();
  @Output() showloader = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public permissionchecker: PermissioncheckerService,
  ) {
  }

  openDialog(mode: string = 'create') {
    switch (mode) {
      case 'create':
        this.dialogRefDataMode = 'create';
        break;
      case 'edit': {
        this.dialogRefDataMode = 'edit';
        break;
      }
    }

    const dialogRef = this.dialog.open(UserEditComponent, {
      width: '1200px',
      data: {
        user: this.usertobeedited,
        mode: this.dialogRefDataMode,
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed === true) {
        this.snackBar.open('User erfolgreich gespeichert!', 'OK', {
          duration: 4000,
        });
        this.showLoaderOnParent();
        this.requestRefresh();
      } else {
        this.snackBar.open('Ihre Änderungen wurden nicht gespeichert', 'OK', {
          duration: 4000,
        });
      }
    });
  }

  showLoaderOnParent() {
    this.showloader.emit('Show-Loader');
  }

  requestRefresh() {
    this.refreshrequest.emit('Request-Refresh');
  }
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {
  userseditresponse;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  roles: object;
  selectedroles = [];
  userseditform: FormGroup;
  submitted = false;
  isSaving = false;
  loading = true;
  firstname = new FormControl((this.data.mode === 'edit' ? this.data.user.firstname : ''), Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(255)]));
  lastname = new FormControl((this.data.mode === 'edit' ? this.data.user.lastname : ''), Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(255)]));
  phone = new FormControl((this.data.mode === 'edit' ? this.data.user.phone : ''), Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(255)]));
  rolesFormControl = new FormControl((this.data.mode === 'edit' ? this.selectedroles : []), Validators.required);
  department = new FormControl((this.data.mode === 'edit' ? this.data.user.department : ''), Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(255)]));
  broadcasterFormControl = new FormControl((this.data.mode === 'edit' ? this.data.user.broadcaster_id : ''), Validators.required);
  email = new FormControl({
    value: (this.data.mode === 'edit' ? this.data.user.email : ''),
    disabled: this.data.mode !== 'create',
  }, Validators.compose([Validators.required, Validators.email, Validators.maxLength(255)]));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserEditButtonComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public permissionchecker: PermissioncheckerService,
    private titleService: Title
  ) {
  }

  deleteUser() {
    this.apiService.deleteUser(this.data.user.id).then(
      data => {
        this.userseditresponse = data;
        this.submitted = true;
        this.isSaving = false;
        this.closeDialogSaving(true);
      },
    ).catch((error: any) => {
      this.submitted = false;
      this.isSaving = false;
      this.snackBar.open('Es ist ein Fehler beim löschen aufgetreten.', 'OK', {
        duration: 8000,
      });
      console.log(error);
    });
  }

  postUser(mode) {
    if (this.userseditform.invalid) {
      return;
    } else {
      this.isSaving = true;
    }

    if (mode === 'create') {
      this.apiService.createUser(this.userseditform.value).then(
        data => {
          this.userseditresponse = data;
          this.submitted = true;
          this.isSaving = false;
          this.closeDialogSaving(true);
        },
      ).catch((error: any) => {
        this.submitted = false;
        this.isSaving = false;
        this.snackBar.open('Fehler: ' + error + ' Der User wurde nicht angelegt.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      });
    }

    if (mode === 'edit') {
      const updatedata = this.userseditform.value;
      updatedata.userid = this.data.user.id;

      this.apiService.updateUser(updatedata).then(
        data => {
          this.userseditresponse = data;
          this.submitted = true;
          this.isSaving = false;
          this.closeDialogSaving(true);
        },
      ).catch((error: any) => {
        this.submitted = false;
        this.isSaving = false;
        this.snackBar.open('Es ist ein Fehler aufgetreten. Ihre Änderungen wurden nicht gespeichert.', 'OK', {
          duration: 8000,
        });
        console.log(error);
      });
    }
  }

  closeDialogSaving(success: true | false) {
    this.dialogRef.close(success);
  }

  checkBroadcasterOrCompany(trigger: string = 'both') {
    if (trigger === 'company' || trigger === 'both') {
      if (this.userseditform.value.company) {
        this.userseditform.controls.broadcaster_id.disable();
      } else {
        this.userseditform.controls.broadcaster_id.enable();
      }
    }

    if (trigger === 'broadcaster' || trigger === 'both') {
      if (this.userseditform.value.broadcaster_id !== 'none') {
        this.userseditform.controls.company.disable();
      } else {
        this.userseditform.controls.company.enable();
      }
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('GVR-Tool | Benutzer bearbeiten');
    if (this.data.mode === 'edit') {
      for (const selectedrole of this.data.user.allRoles) {
        this.selectedroles.push(selectedrole.name);
      }
    }

    this.apiService.getRoles().subscribe((roles) => {
      this.roles = roles;
      this.loading = false;
    });

    this.userseditform = this.formBuilder.group({
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      roles: this.rolesFormControl,
      phone: this.phone,
      broadcaster_id: this.broadcasterFormControl,
      company: [{
        value: (this.data.mode === 'edit' ? this.data.user.company : ''),
        disabled: (this.data.mode === 'edit' ? this.data.user.broadcaster_id !== 0 : false)
      }],
      department: this.department,
    });
  }
}

