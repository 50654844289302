import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../_services/api.service';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PublicationsDataSource} from '../_datasources/PublicationsDataSource';
import {tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {merge} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {Ruleset} from '../ruleset';
import {newArray} from '@angular/compiler/src/util';

@Component({
  selector: 'app-publicationlist',
  templateUrl: './publicationlist.component.html'
})
export class PublicationlistComponent implements OnInit {

  displayedColumns = ['id', 'playout_title', 'genre', 'broadcaster_name', 'leading_broadcaster_name', 'start', 'duration_minutes', 'ruleset_applied', 'transaction_amount', 'transaction_cost'];
  dataSource = new PublicationsDataSource(this.apiService);
  selection = new SelectionModel(false, []);
  expertSearchForm: FormGroup;
  simpleSearchForm: FormGroup;
  loading = false;
  rules: Ruleset[] = [];
  genres;
  broadcasters: object = JSON.parse(localStorage.getItem('broadcasters'));
  showexpertsearch = false;
  search = {detailSearch: false, data: {playout_title: '', genre: '', broadcaster: '', leading_broadcaster: '', start_datetime: '', end_datetime: '', is_remunerated: '', simpleSearchInput: ''}};

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private apiService: ApiService,
    public permissionchecker: PermissioncheckerService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('GVR-Tool | Publikationsliste');

    if (this.permissionchecker.userHasRight('read-rulesets')) {
      this.apiService.getRulesets().subscribe((rules) => {
        this.rules = rules;
      });
    }

    this.apiService.getGenres().subscribe((genres) => {
      this.genres = genres;
    });

    this.initSearch();
    this.setSearch();
    this.updatePublicationList();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.updatePublicationList())
      )
      .subscribe();
  }

  routingToPublication(id) {
    this.router.navigate(['/publication/' + id]);
  }

  setSearch() {
    const savedSearch = JSON.parse(localStorage.getItem('search'));

    if (savedSearch === null) {
      return false;
    }

    this.search = savedSearch;

    if (this.search.detailSearch) {
      this.expertSearchForm.patchValue({
        playout_title: this.search.data.playout_title,
        genre: this.search.data.genre,
        broadcaster: this.search.data.broadcaster,
        leading_broadcaster: this.search.data.leading_broadcaster,
        start_datetime: this.search.data.start_datetime,
        end_datetime: this.search.data.end_datetime,
        is_remunerated: this.search.data.is_remunerated,
      });

      this.showexpertsearch = true;
    } else {
      this.simpleSearchForm.patchValue({
        simpleSearchInput: this.search.data.simpleSearchInput !== undefined ? this.search.data.simpleSearchInput : this.search.data,
      });
    }
  }

  initSearch() {
    this.expertSearchForm = this.formBuilder.group({
      playout_title: [null],
      genre: [null],
      broadcaster: [null],
      leading_broadcaster: [null],
      start_datetime: [null],
      end_datetime: [null],
      is_remunerated: [null],
    });

    this.simpleSearchForm = this.formBuilder.group({
      simpleSearchInput: ['']
    });
  }

  updatePublicationList() {
    localStorage.setItem('search', JSON.stringify(this.search));
    this.dataSource.loadPublications({
      search: this.search,
      pageNumber: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize,
      sortOrder: this.sort.direction,
      sortField: this.sort.active
    });
    this.dataSource.publicationsPayload.asObservable().subscribe(data => {
      // @ts-ignore
      this.paginator.length = data.total;
    });
  }

  toggleExpertSearch() {
    this.showexpertsearch = !this.showexpertsearch;

    if (this.showexpertsearch === false) {
      this.resetExpertSearch();
      this.searchPublications();
    }
  }

  resetExpertSearch(doSearch = false) {
    this.expertSearchForm = this.formBuilder.group({
      playout_title: [null],
      genre: [null],
      broadcaster: [null],
      leading_broadcaster: [null],
      start_datetime: [null],
      end_datetime: [null],
      is_remunerated: [null],
    });

    if (doSearch === true) {
      this.detailSearchPublications();
    }
  }

  searchPublications() {
    this.selection.clear();
    if (this.simpleSearchForm.invalid) {
      return;
    }
    this.paginator.pageIndex = 0;
    this.search.data = this.simpleSearchForm.value.simpleSearchInput;
    this.search.detailSearch = false;
    this.updatePublicationList();
  }

  loadPublications() {
    this.updatePublicationList();
  }

  detailSearchPublications() {
    this.selection.clear();
    if (this.expertSearchForm.invalid) {
      return;
    }
    this.paginator.pageIndex = 0;
    this.search.data = this.expertSearchForm.value;
    this.search.detailSearch = true;
    this.updatePublicationList();
  }

  exportPublications() {
    let publicationsExport;
    const searchConfig = {
      search: this.search,
      pageNumber: 0,
      pageSize: 99999,
      sortOrder: this.sort.direction,
      sortField: this.sort.active
    };
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    const columnNames = {
      broadcaster_id: 'nutzende Anstalt-ID',
      broadcaster_name: 'nutzende Anstalt',
      created_at: 'Angelegt',
      deleted_at: 'Gelöscht am',
      duration_minutes: 'Länge (min)',
      epg_id: 'EPD-ID',
      genre: 'Genre',
      id: 'ID',
      is_simulated: 'Simulation?',
      is_under_zero: 'Nachvergütet?',
      leading_broadcaster_name: 'Federführung',
      playout_title: 'Titel',
      poc_id: 'POC-ID',
      productions_id: 'Produktions-ID',
      start: 'Ausstrahlung Start',
      stop: 'Ausstrahlung Ende',
      ruleset_applied: 'Regelset',
      transaction_amount: 'Verbrauch',
      transaction_cost: 'Kosten',
      transactions: 'Transaktionen',
      updated_at: 'Aktualisiert',
    };
    const columnsToExport = [
      'created_at',
      'updated_at',
      'start',
      'duration_minutes',
      'genre',
      'broadcaster_name',
      'leading_broadcaster_name',
      'playout_title',
      'ruleset_applied',
      'transaction_amount',
      'transaction_cost',
    ];

    this.loading = true;

    if (this.showexpertsearch === true) {

      if (this.expertSearchForm.invalid) {
        this.loading = false;
        return;
      }
      this.search.data = this.expertSearchForm.value;
      this.search.detailSearch = true;

      this.apiService.detailSearchPublications(searchConfig).then(publications => {
        publicationsExport = JSON.parse(JSON.stringify(publications.data));

        for (const singlePublication of publicationsExport) {
          let singlePublicationRulesets = '';
          let singlePublicationAmounts = '';
          let singlePublicationCosts = '';

          for (const singlePublicationTransactions of singlePublication.transactions) {
            singlePublicationRulesets += singlePublicationTransactions.ruleset_applied + ', ';
            singlePublicationAmounts += singlePublicationTransactions.amount + ', ';
            singlePublicationCosts += singlePublicationTransactions.cost + ', ';
          }

          singlePublication.ruleset_applied = singlePublicationRulesets.substring(0, singlePublicationRulesets.length - 2);
          singlePublication.transaction_amount = singlePublicationAmounts.substring(0, singlePublicationAmounts.length - 2);
          singlePublication.transaction_cost = singlePublicationCosts.substring(0, singlePublicationCosts.length - 2);

          for (const singlePublicationProperty of Object.keys(singlePublication)) {
            if (!columnsToExport.includes(singlePublicationProperty)) {
              delete singlePublication[singlePublicationProperty];
            }
          }
        }

        writeToExcel();
        this.loading = false;
      });

    } else {

      if (this.simpleSearchForm.invalid) {
        this.loading = false;
        return;
      }
      this.search.data = this.simpleSearchForm.value.simpleSearchInput;
      this.search.detailSearch = false;

      this.apiService.searchPublications(searchConfig).then(publications => {
        publicationsExport = JSON.parse(JSON.stringify(publications.data));

        for (const singlePublication of publicationsExport) {
          let singlePublicationRulesets = '';
          let singlePublicationAmounts = '';
          let singlePublicationCosts = '';

          for (const singlePublicationTransactions of singlePublication.transactions) {
            singlePublicationRulesets += singlePublicationTransactions.ruleset_applied + ', ';
            singlePublicationAmounts += singlePublicationTransactions.amount + ', ';
            singlePublicationCosts += singlePublicationTransactions.cost + ', ';
          }

          singlePublication.ruleset_applied = singlePublicationRulesets.substring(0, singlePublicationRulesets.length - 2);
          singlePublication.transaction_amount = singlePublicationAmounts.substring(0, singlePublicationAmounts.length - 2);
          singlePublication.transaction_cost = singlePublicationCosts.substring(0, singlePublicationCosts.length - 2);

          for (const singlePublicationProperty of Object.keys(singlePublication)) {
            if (!columnsToExport.includes(singlePublicationProperty)) {
              delete singlePublication[singlePublicationProperty];
            }
          }
        }

        writeToExcel();
        this.loading = false;
      });

    }

    function writeToExcel() {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(publicationsExport);
      const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + '1';
        if (!worksheet[address]) {
          continue;
        }
        worksheet[address].v = columnNames[worksheet[address].v];
      }
      XLSX.writeFile(workbook, 'NDR-GVR-Tool-Export_Publikationen_' + currentDate + '_' + currentTime + '.xlsx');
    }
  }
}
