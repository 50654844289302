<h1 mat-dialog-title *ngIf="data.mode === 'create'">Neues Regelwerk anlegen</h1>
<h1 mat-dialog-title *ngIf="data.mode === 'duplicate'">Regelwerk duplizieren ({{data.ruleset.name }})</h1>
<h1 mat-dialog-title *ngIf="data.mode === 'edit'">Regelwerk bearbeiten ({{data.ruleset.name }})</h1>

<form [formGroup]="ruleseditform" (ngSubmit)="postRuleset(data.mode)">

  <div mat-dialog-content>

    <div class="rule-general row">
      <div class="col-10 rule-general-name">
        <mat-form-field>
          <input name="ruleset_name" formControlName="ruleset_name" matInput placeholder="Regelname">
        </mat-form-field>
      </div>

      <div class="col-2 rule-general-active">
        <mat-checkbox color="primary" color="primary" name="ruleset_active" formControlName="ruleset_active">
          <label>aktiv</label>
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <input name="ruleset_valid_from" [matDatepicker]="validfrompicker" formControlName="ruleset_valid_from" matInput placeholder="Gültig von" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.valid_from : '')">
          <mat-datepicker-toggle matSuffix [for]="validfrompicker"></mat-datepicker-toggle>
          <mat-datepicker #validfrompicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <input name="ruleset_valid_to" [matDatepicker]="validtopicker" formControlName="ruleset_valid_to" matInput placeholder="Gültig bis" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.valid_to : '')">
          <mat-datepicker-toggle matSuffix [for]="validtopicker"></mat-datepicker-toggle>
          <mat-datepicker #validtopicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <mat-label>Genre</mat-label>
          <mat-select [attr.role]="'selectbox'" formControlName="ruleset_genre" name="ruleset_genre">
            <mat-option value="Spielfilm">Spielfilm</mat-option>
            <mat-option value="Dokumentation">Dokumentation</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleseditform.controls['ruleset_genre'].hasError('required')">
            Bitte geben Sie ein Genre an.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field>
          <input matInput placeholder="GVR-ID" formControlName="gvr_id" name="gvr_id" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.gvr_id : '')">
          <mat-error *ngIf="ruleseditform.controls['gvr_id'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <input matInput placeholder="Startguthaben" formControlName="initial_points" name="initial_points" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.initial_points : 0)">
          <mat-error *ngIf="ruleseditform.controls['initial_points'].hasError('pattern')">
            Bitte das Schema "12345 oder 12345.67" einhalten.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['initial_points'].hasError('min')">
            Die eingegebene Zahl darf nicht kleiner als 1 sein.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['initial_points'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <input matInput placeholder="Erstvergütung" formControlName="initial_payment" name="initial_payment" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.initial_payment : 0)">
          <mat-error *ngIf="ruleseditform.controls['initial_payment'].hasError('pattern')">
            Bitte das Schema "12345 oder 12345.67" einhalten.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['initial_payment'].hasError('min')">
            Die eingegebene Zahl darf nicht kleiner als 1 sein.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['initial_payment'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <input matInput placeholder="Kosten pro Punkt" formControlName="cost_per_point_in_euro" name="cost_per_point_in_euro" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.cost_per_point_in_euro : 0)">
          <mat-error *ngIf="ruleseditform.controls['cost_per_point_in_euro'].hasError('pattern')">
            Bitte das Schema "12345 oder 12345.67" einhalten.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['cost_per_point_in_euro'].hasError('min')">
            Die eingegebene Zahl darf nicht kleiner als 1 sein.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['cost_per_point_in_euro'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-4" *ngIf="ruleseditform.controls['ruleset_genre'].value === 'Dokumentation'">
        <mat-form-field>
          <input matInput type="number" placeholder="Erschöpfung nach X Ausstrahlungen (0 = unendlich)" formControlName="ruleset_packet_end_by_publication_count" name="ruleset_packet_end_by_publication_count" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.packet_end_by_publication_count : 0)">
          <mat-error *ngIf="ruleseditform.controls['ruleset_packet_end_by_publication_count'].hasError('pattern')">
            Der eingegebene Wert muss eine Zahl sein.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['ruleset_packet_end_by_publication_count'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-4" *ngIf="ruleseditform.controls['ruleset_genre'].value === 'Dokumentation'">
        <mat-form-field>
          <input matInput type="number" placeholder="Mindestkosten Erstausstrahlung" formControlName="ruleset_initial_publication_amount" name="ruleset_initial_publication_amount" [value]="(data.mode === 'edit' && data.ruleset ? data.ruleset.initial_publication_amount : 0)">
          <mat-error *ngIf="ruleseditform.controls['ruleset_initial_publication_amount'].hasError('pattern')">
            Bitte das Schema "12345 oder 12345.67" einhalten.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['ruleset_initial_publication_amount'].hasError('min')">
            Die eingegebene Zahl darf nicht kleiner als 1 sein.
          </mat-error>
          <mat-error *ngIf="ruleseditform.controls['ruleset_initial_publication_amount'].hasError('required')">
            Zwingend erforderlich.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <hr>

    <div class="rule-slots">

      <div class="rule-single-slot row" formArrayName="slots" *ngFor="let slot of rulesEditFormData.controls; let i = index;">
        <div class="col-1 slot-name">
          <strong>{{i + 1}}.</strong>
        </div>
        <div class="col-10" [formGroupName]="i">

          <div class="row">
            <div class="col-6 col-md-3">
              <mat-form-field>
                <input matInput type="time" name="ruleset_slot_start_{{i}}" formControlName="ruleset_slot_start" placeholder="Slot-Beginn">
              </mat-form-field>
            </div>

            <div class="col-6 col-md-3">
              <mat-form-field>
                <input matInput type="time" name="ruleset_slot_end_{{i}}" formControlName="ruleset_slot_end" placeholder="Slot-Ende">
              </mat-form-field>
            </div>

            <div class="col-6 col-md-3">
              <mat-form-field>
                <input matInput name="ruleset_slot_points_{{i}}" formControlName="ruleset_slot_points" placeholder="Punktefaktor">
              </mat-form-field>
            </div>

            <div class="col-6 col-md-3 center-checkbox">
              <mat-checkbox color="primary" name="ruleset_slot_service_rep_allowed_{{i}}" formControlName="ruleset_slot_service_rep_allowed">
                <label>Wiederholungen erlaubt</label>
              </mat-checkbox>
            </div>
          </div>

        </div>

        <div class="col-1 slot-delete" *ngIf="i > 0">
          <mat-icon (click)="removeSlotGroup(i);">delete</mat-icon>
        </div>

      </div>

      <div class="rules-add-slot">
        <button type="button" class="rules-add-slot-button" mat-mini-fab color="primary" (click)="addSlotGroup()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

    </div>

    <hr>

    <div class="row broadcaster-list">
      <div class="single-broadcaster col-md-6 col-lg-4" formArrayName="broadcasters" *ngFor="let broadcaster of broadcasters; let i = index;">
        <div class="single-broadcaster-inner" [formGroupName]="i">

          <label class="broadcaster-headline">{{broadcaster.name}}</label>

          <div class="basefactor-container">
            <mat-form-field>
              <input matInput placeholder="Basisfaktor" type="number" name="ruleset_broadcaster_points_{{broadcaster.id}}" formControlName="ruleset_broadcaster_points">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="NV-Faktor" type="number" name="basefactor_score_under_zero_{{broadcaster.id}}" formControlName="basefactor_score_under_zero">
              <mat-error *ngIf="'basefactor_score_under_zero_'+broadcaster.id+'.invalid'">Ungültiger Wert</mat-error>
            </mat-form-field>
          </div>

          <div class="validity-container">
            <mat-form-field matTooltip=">>0<< entspricht unbegrenzter Gültigkeit.">
              <input type="number" matInput name="valid_for_amount_{{broadcaster.id}}" formControlName="valid_for_amount" placeholder="Gültigkeit" type="number">
            </mat-form-field>

            <mat-select [attr.role]="'selectbox'" name="valid_for_unit_{{broadcaster.id}}" formControlName="valid_for_unit">
              <mat-option value="Hour">Stunden</mat-option>
              <mat-option value="Month">Monate</mat-option>
              <mat-option value="Year">Jahre</mat-option>
            </mat-select>
          </div>

          <mat-checkbox name="dateinterval_includes_sundays_{{broadcaster.id}}" formControlName="dateinterval_includes_sundays" color="primary">
            <label>Sonntage berücksichtigen</label>
          </mat-checkbox>

          <mat-checkbox name="dateinterval_includes_legal_holidays_{{broadcaster.id}}" formControlName="dateinterval_includes_legal_holidays" color="primary">
            <label>Feiertage berücksichtigen</label>
          </mat-checkbox>

          <mat-checkbox name="use_slot_factor_{{broadcaster.id}}" formControlName="use_slot_factor" color="primary">
            <label>Slot-Faktor berücksichten</label>
          </mat-checkbox>

        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="closeDialogSaving(false)">abbrechen</button>
    <button mat-raised-button color="primary" [disabled]="isSaving">
      <mat-spinner color="primary" diameter="15" [hidden]="isSaving === false"></mat-spinner>
      Absenden
    </button>
  </div>
</form>

<div class="loading-spinner" *ngIf="loading === true">
  <mat-spinner color="primary" diameter="75"></mat-spinner>
</div>
