<h1 mat-dialog-title>Hilfe</h1>
<strong class="mat-dialog-subtitle">
  <span class="dialog-description">Servicekontakt<br>ARD Play-Out-Center, Potsdam</span>
  <span class="dialog-title">Rolf Czernotzky</span>
</strong>

<div mat-dialog-content>
  <div class="contact-data">

    <span>ARD Play-Out-Center
      <br><i>Redaktion Programmdaten und Interaktive Dienste /iTV</i>
      <br><a href="www.programm.ard.de">www.programm.ard.de</a>
    </span>

    <span>Marlene-Dietrich-Allee 20
    <br>14482 Potsdam</span>

    <span>
      E-Mail:
      <a href="mailto:gvr@ard-poc.de">GVR@ard-poc.de</a>
    </span>

    <a href="www.ard-digital.de">www.ard-digital.de</a>

  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="this.dialogRef.close()">OK</button>
</div>
