<h1 mat-dialog-title>GVR-Rechner</h1>
<strong class="mat-dialog-subtitle">
  <span class="dialog-description">Produktion:</span>
  <span class="dialog-title">{{data.productiondata[0].title}}</span>
</strong>

<form [formGroup]="calculationform" (ngSubmit)="calculatePoints()">
  <div mat-dialog-content>
    <mat-form-field>
      <input name="start" formControlName="startTime" type="time" matInput placeholder="Uhrzeit" (change)="resetCalculation()">
    </mat-form-field>

    <mat-form-field>
      <input name="date" [matDatepicker]="picker" formControlName="startDate" matInput placeholder="Datum" (change)="resetCalculation()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>nutzende Anstalt</mat-label>
      <mat-select [attr.role]="'selectbox'" name="broadcaster_id" formControlName="broadcaster_id" (selectionChange)="resetCalculation()">
        <mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.id" [disabled]="broadcaster.is_production_company === 1">{{broadcaster.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="result-container" *ngIf="calculationresult && submitted === true">
    <ng-container *ngFor="let result of calculationresult | keyvalue">
      <div class="single-calculation-result">
        <strong class="single-headline">Berechnung nach "{{findRulesetByID(result.key).name}}":</strong>
        {{result.value[1].deduction}} Punkte, <span>{{result.value[1].cost}} Euro</span>

        <strong class="d-block mt-1">Nachvergütung bei aufgebrauchtem Guthaben:</strong>
        {{result.value[0].deduction}} Punkte, <span>{{result.value[0].cost}} Euro pro Nachvergütungsberechtigten</span>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="this.dialogRef.close()">abbrechen</button>


    <div class="button-wrapper">
      <button type="button" mat-flat-button color="accent" (click)="simulateBroadcast()" [disabled]="isSaving === true" *ngIf="permissionchecker.userHasRights(['create-broadcast', 'read-simulator'])">
        <mat-spinner color="primary" diameter="15" [hidden]="showSpinnerOnSimulate === false"></mat-spinner>
        Simulation
      </button>
      <button type="submit" mat-raised-button color="primary" cdkFocusInitial [disabled]="isSaving === true">
        <mat-spinner color="primary" diameter="15" [hidden]="showSpinnerOnSubmit === false"></mat-spinner>
        Berechnen
      </button>
    </div>
  </div>
</form>
