import { MatDatepickerIntl } from '@angular/material/datepicker';

export function getGermanDatepickerIntl() {
  const datepickerIntl = new MatDatepickerIntl();

  datepickerIntl.openCalendarLabel = 'Datumsauswahl öffnen';
  datepickerIntl.nextMonthLabel = 'Nächster Monat';
  datepickerIntl.prevMonthLabel = 'Vorheriger Monat';
  datepickerIntl.switchToMultiYearViewLabel = 'Jahres auswahl';
  datepickerIntl.switchToMonthViewLabel = 'Datums auswahl';
  datepickerIntl.prevMultiYearLabel = 'Vorherige 20 Jahre';
  datepickerIntl.nextMultiYearLabel = 'Nächste 20 jahre';
  datepickerIntl.nextYearLabel = 'Nächstes Jahr';
  datepickerIntl.prevYearLabel = 'Vorheriges Jahr';

  return datepickerIntl;
}
