export const environment = {
  production: false,
  apiHostProtocol: '//',
  apiHost: '', // needed for local development
  apiPort: ':8443',
  apiPath: '/api/v1',
  serviceMenu: {
    excelList: 'https://share.ard-zdf-box.de/apps/files/?dir=/Datenmeldung/Vorlage_Datenmeldung&fileid=66983094',
    help: 'https://share.ard-zdf-box.de/s/PzaQpLbyfksLZe9',
  },
};
