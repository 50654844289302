import {Component, Input, Output, Inject, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../_services/api.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker } from '@angular/material/datepicker';
import {EventEmitter} from '@angular/core';
import {PermissioncheckerService} from '../_services/permissionchecker.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-rules-edit-button',
    templateUrl: './rules-edit-button.component.html'
})
export class RulesEditButtonComponent {
    dialogRefDataMode;
    rulesdeleteresponse;

    @Input() rulesettobeedited = null;
    @Output() refreshrequest = new EventEmitter<string>();
    @Output() showloader = new EventEmitter<string>();

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private apiService: ApiService,
        public permissionchecker: PermissioncheckerService,
    ) {
    }

    openDialog(mode: string = 'create') {
        switch (mode) {
            case 'create':
                this.dialogRefDataMode = 'create';
                break;
            case 'duplicate':
                this.dialogRefDataMode = 'duplicate';
                break;
            case 'edit': {
                this.dialogRefDataMode = 'edit';
                break;
            }
        }

        const dialogRef = this.dialog.open(RulesEditComponent, {
            width: '1200px',
            data: {
                ruleset: this.rulesettobeedited,
                mode: this.dialogRefDataMode,
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed === true) {
                this.snackBar.open('Regelset erfolgreich gespeichert!', 'OK', {
                    duration: 4000,
                });
                this.showLoaderOnParent();
                this.requestRefresh();
            } else {
                this.snackBar.open('Ihre Änderungen wurden nicht gespeichert', 'OK', {
                    duration: 4000,
                });
            }
        });
    }

    deleteRuleset(rulesetid) {
        this.showLoaderOnParent();
        this.apiService.deleteRuleset(rulesetid).then(
            data => {
                this.rulesdeleteresponse = data;
                this.requestRefresh();
            },
        );
    }

    showLoaderOnParent() {
        this.showloader.emit('Show-Loader');
    }

    requestRefresh() {
        this.refreshrequest.emit('Request-Refresh');
    }
}

@Component({
    selector: 'app-rules-edit',
    templateUrl: './rules-edit.component.html'
})
export class RulesEditComponent implements OnInit, AfterViewInit {
    ruleseditresponse;
    broadcasters = JSON.parse(localStorage.getItem('broadcasters'));
    ruleseditform: FormGroup;
    slotsarray: FormArray;
    broadcastersarray: FormArray;
    submitted = false;
    isSaving = false;
    loading = true;

    @ViewChild('validfrompicker') validfrompicker: MatDatepicker<any>;
    @ViewChild('validtopicker') validtopicker: MatDatepicker<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RulesEditButtonComponent>,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private titleService: Title
    ) {
    }

    postRuleset(mode) {
        if (this.ruleseditform.invalid) {
            this.snackBar.open('Es wurden teilweise ungültige Daten gefunden - bitte überprüfen Sie die eingetragenen Werte.', 'OK', {
              duration: 8000,
            });
            return;
        } else {
            this.isSaving = true;
        }

        if (mode === 'create' || mode === 'duplicate') {
            this.apiService.createRuleset(this.ruleseditform.value).then(
                data => {
                    this.ruleseditresponse = data;
                    this.submitted = true;
                    this.isSaving = false;
                    this.closeDialogSaving(true);
                },
            ).catch((error: any) => {
                this.submitted = false;
                this.isSaving = false;

                if (error.status === 422) {
                    this.snackBar.open('Fehler! Es gibt Überschneidungen in den Zeiten der Sendeslots. Bitte prüfen Sie diese.', 'OK', {
                        duration: 8000,
                    });
                } else {
                    this.snackBar.open('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'OK', {
                        duration: 8000,
                    });
                    console.log(error);
                }
            });
        }

        if (mode === 'edit') {
            this.apiService.updateRuleset(this.ruleseditform.value).then(
                data => {
                    this.ruleseditresponse = data;
                    this.submitted = true;
                    this.isSaving = false;
                    this.closeDialogSaving(true);
                },
            ).catch((error: any) => {
                this.submitted = false;
                this.isSaving = false;

                if (error.status === 422) {
                    this.snackBar.open('Fehler! Es gibt Überschneidungen in den Zeiten der Sendeslots. Bitte prüfen Sie diese.', 'OK', {
                        duration: 8000,
                    });
                } else {
                    this.snackBar.open('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'OK', {
                        duration: 8000,
                    });
                    console.log(error);
                }
            });
        }
    }

    closeDialogSaving(success: true | false) {
        this.dialogRef.close(success);
    }

    createBroadcasterGroup(): FormGroup {
        return this.formBuilder.group({
            ruleset_broadcaster_id: ['', Validators.required],
            ruleset_broadcaster_points: ['', Validators.compose(
                [Validators.min(0), Validators.required])],
            basefactor_score_under_zero: ['', Validators.compose(
                [Validators.min(0), Validators.required])],
            valid_for_amount: [0],
            valid_for_unit: ['Hour'],
            use_slot_factor: [true, Validators.required],
            dateinterval_includes_legal_holidays: [true, Validators.required],
            dateinterval_includes_sundays: [true, Validators.required],
        });
    }

    addBroadcasterGroup(): void {
        this.broadcastersarray = this.ruleseditform.get('broadcasters') as FormArray;
        this.broadcastersarray.push(this.createBroadcasterGroup());
    }

    updateBroadcasterGroups(broadcasters) {
      const broadcasterGroup = this.ruleseditform.get('broadcasters') as FormArray;
      let broadcasterindex = 0;
      broadcasterGroup.clear();

      for (const broadcaster of broadcasters) {
          this.addBroadcasterGroup();
          this.broadcastersarray.at(broadcasterindex).patchValue({
            ruleset_broadcaster_id: broadcaster.id,
            ruleset_broadcaster_points: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).basefactor : 1,
            basefactor_score_under_zero: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).basefactor_score_under_zero : 1,
            valid_for_amount: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).valid_for_amount : 0,
            valid_for_unit: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).valid_for_unit : 'Hour',
            use_slot_factor: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).use_slot_factor : true,
            dateinterval_includes_legal_holidays: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).dateinterval_includes_legal_holidays : true,
            dateinterval_includes_sundays: this.getBroadcasterBasicFactorByID(broadcaster.id) ? this.getBroadcasterBasicFactorByID(broadcaster.id).dateinterval_includes_sundays : true,
          });
          broadcasterindex = broadcasterindex + 1;
      }
    }

    createSlotGroup(): FormGroup {
        return this.formBuilder.group({
            ruleset_slot_start: ['', Validators.required],
            ruleset_slot_end: ['', Validators.required],
            ruleset_slot_points: ['', Validators.compose(
                [Validators.min(0.1), Validators.required])],
            ruleset_slot_service_rep_allowed: [false],
        });
    }

    addSlotGroup(): void {
        this.slotsarray = this.ruleseditform.get('slots') as FormArray;
        this.slotsarray.push(this.createSlotGroup());
    }

    removeSlotGroup(index): void {
        this.slotsarray.removeAt(index);
    }

    updateSlotGroups(slots) {
        const slotGroup = this.ruleseditform.get('slots') as FormArray;
        let slotIndex = 0;
        slotGroup.clear();

        for (const slot of slots) {
            this.addSlotGroup();
            this.slotsarray.at(slotIndex).patchValue({
                ruleset_slot_start: slot.start,
                ruleset_slot_end: slot.end,
                ruleset_slot_points: slot.slot_factor,
                ruleset_slot_service_rep_allowed: (slot.service_repetition_allowed === 1),
            });
            slotIndex = slotIndex + 1;
        }
    }

    findLocalBroadcasterByID(idToFind) {
      return this.broadcasters.find(x => x.id === idToFind);
    }

    getBroadcasterBasicFactorByID(idToFind) {
      return this.data.ruleset.broadcasterBasicFactors.find(x => x.broadcaster_id === idToFind);
    }

    get rulesEditFormData() {
        return this.ruleseditform.get('slots') as FormArray;
    }

    ngOnInit(): void {
        this.titleService.setTitle('GVR-Tool | Regeln bearbeiten');
        this.ruleseditform = this.formBuilder.group({
            ruleset_id: [(this.data.mode === 'edit' ? this.data.ruleset.id : 0)],
            ruleset_name: [(this.data.mode === 'edit' ? this.data.ruleset.name : ''), Validators.required],
            ruleset_genre: [(this.data.mode === 'edit' ? this.data.ruleset.genre : ''), Validators.required],
            ruleset_valid_from: [(this.data.mode === 'edit' ? this.data.ruleset.valid_from : '')],
            ruleset_valid_to: [(this.data.mode === 'edit' ? this.data.ruleset.valid_to : '')],
            ruleset_active: [(this.data.mode === 'edit' ? this.data.ruleset.active : false)],
            gvr_id: [(this.data.mode === 'edit' ? this.data.ruleset.gvr_id : ''), Validators.required],
            initial_points: [(this.data.mode === 'edit' ? this.data.ruleset.initial_points : 0), [Validators.min(1), Validators.pattern(/^[0-9]+\.?[0-9]{0,2}$/), Validators.required]],
            initial_payment: [(this.data.mode === 'edit' ? this.data.ruleset.initial_payment : 0), [Validators.min(1), Validators.pattern(/^[0-9]+\.?[0-9]{0,2}$/), Validators.required]],
            cost_per_point_in_euro: [(this.data.mode === 'edit' ? this.data.ruleset.cost_per_point_in_euro : 0),  [Validators.min(1), Validators.pattern(/^[0-9]+\.?[0-9]{0,2}$/), Validators.required]],
            ruleset_packet_end_by_publication_count: [(this.data.mode === 'edit' ? this.data.ruleset.packet_end_by_publication_count : 0),  [Validators.required]],
            ruleset_initial_publication_amount: [(this.data.mode === 'edit' ? this.data.ruleset.initial_publication_amount : 0),  [Validators.pattern(/^[0-9]+\.?[0-9]{0,2}$/), Validators.required]],
            slots: this.formBuilder.array([this.createSlotGroup()]),
            broadcasters: this.formBuilder.array([this.createBroadcasterGroup()]),
        });

        this.updateBroadcasterGroups(this.broadcasters);
        if (this.data.ruleset) {
            this.updateSlotGroups(this.data.ruleset.slots);
        }

        this.loading = false;
    }

    ngAfterViewInit(): void {
        if (this.data.mode === 'edit' && this.data.ruleset.valid_from && this.data.ruleset.valid_to) {
            const validfromdate = new Date(this.data.ruleset.valid_from);
            const validtodate = new Date(this.data.ruleset.valid_to);
            setTimeout(() => this.validfrompicker.select(validfromdate), 0);
            setTimeout(() => this.validtopicker.select(validtodate), 0);
        }
    }
}
