import { Component } from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';

import { AuthenticationService } from './_services';
import { User, Role } from './_models';

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'gvr';
  router: string;
  currentUser: User;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  constructor(
    private _router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
    this.authenticationService.logout();
    this._router.navigate(['/login']);
  }
}
