import { Component, OnInit } from '@angular/core';
import { PublicationlistComponent} from '../publicationlist/publicationlist.component';

@Component({
  selector: 'app-publicationlist-search',
  templateUrl: './publicationlist-search.component.html'
})
export class PublicationlistSearchComponent implements OnInit {

  constructor(
    public publicationList: PublicationlistComponent,
  ) { }

  ngOnInit(): void {
  }

}
